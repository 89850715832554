import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@popmenu/common-ui';

import { withStyles } from '../../../utils/withStyles';
import errorContainerStyles from '../ErrorContainer/styles';

import { AH } from '../AccessibleHeading';

const NotFoundContainer = props => (
  <div className={props.classes.container}>
    <AH typography align="center" className={props.classes.heading} gutterBottom variant="h3">
      {props.heading || <FormattedMessage id="consumer.not_found.heading" defaultMessage="Sorry." />}
    </AH>
    <Typography align="center" className={props.classes.body} gutterBottom>
      {props.body || (
        <FormattedMessage
          id="consumer.not_found.body"
          defaultMessage="Looks like we couldn't find the page you were looking for."
        />
      )}
    </Typography>
    <Typography align="center" className={props.classes.body}>
      <a href="/">
        <FormattedMessage id="consumer.not_found.link" defaultMessage="Return to home" />
      </a>
    </Typography>
  </div>
);

NotFoundContainer.defaultProps = {
  body: null,
  heading: null,
};

NotFoundContainer.propTypes = {
  body: PropTypes.string,
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string,
};

export default withStyles(errorContainerStyles)(NotFoundContainer);
