import debounce from 'lodash/debounce';

// Will give up waiting for new headings to be rendered after 1 seconds.
const msToWaitForNewHeading = 1000;

enum Status {
  // ScreenReaderModeManager does nothing.
  Disabled,
  // ScreenReaderModeManager enabled screen reader mode.
  // This makes every lazy-loaded section to be rendered, so screen readers can access the list of headings.
  // ScreenReaderModeManager is waiting for new headings to appear finding the topmost one.
  Loading,
  // ScreenReaderModeManager moved the focus to the topmost Lazy-Loaded heading.
  // Now the purspose of ScreenReaderModeManager is over. State stops changing.
  Loaded,
}

// ScreenReaderModeManager manages ScreenReaderModeProvider's state.
// The primary goal is to avoid unnecessary re-renderings updating the React state only when it's actually needed.
export class ScreenReaderModeManager {
  constructor(
    setShowPageHasLoadedMessage: (show: boolean) => void,
    setScreenReaderMode: (enabled: boolean) => void,
  ) {
    this.setShowPageHasLoadedMessage = setShowPageHasLoadedMessage;
    this.setScreenReaderMode = setScreenReaderMode;
  }

  onNewHeading = (top: number, focus: () => void): void => {
    if (this.status === Status.Loading && top < (this.topmostLazyLoadedHeading?.top ?? Number.MAX_VALUE)) {
      this.topmostLazyLoadedHeading = { focus, top };
      this.debouncedFinishLoading();
    }
  };

  enableScreenReaderMode = () => {
    if (this.status === Status.Disabled) {
      this.setScreenReaderMode(true);
      this.startLoading();
    }
  };

  // Private

  private status: Status = Status.Disabled;

  // Data in |topmostLazyLoadedHeading| is provided by children <AH/> components.
  // See app/javascript/consumer/shared/AccessibleHeading.tsx
  private topmostLazyLoadedHeading?: {
    // pixels from top of the page to top of the HTML element of the heading.
    top: number,
    // A call to focus() will cause re-rendering of a child <AH /> heading making it tabIndexable
    // and focused, to make Screen Readers select the heading.
    // This is analogouse to scrollTo, but for screen readers.
    focus: () => void,
  };

  private setShowPageHasLoadedMessage: (show: boolean) => void;

  private setScreenReaderMode: (enabled: boolean) => void;

  private startLoading = () => {
    this.status = Status.Loading;
    this.debouncedFinishLoading();
  };

  private debouncedFinishLoading = debounce(() => {
    if (this.status === Status.Loaded) return;
    this.status = Status.Loaded;
    if (this.topmostLazyLoadedHeading) {
      this.topmostLazyLoadedHeading.focus();
    } else {
      this.setShowPageHasLoadedMessage(true);
    }
  }, msToWaitForNewHeading);
}
