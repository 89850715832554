import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { withRouter } from 'react-router-dom';
import { Drawer } from '@popmenu/common-ui';

import { withStyles } from '../../../../utils/withStyles';
import { withWindowContext } from '../../../../shared/WindowProvider';
import styles from './styles';
import { setShowMobileCartModal } from '../../../../shared/ConsumerActions';
import { useRestaurantContext } from '../../../../utils/useRestaurantContext';

import CartButtonSummary from '../CartButtonSummary';
import { getOrderingPageUrl, useMenuItemCartVariables } from '../MenuItemCartHelpers';
import CartButtonDisplay from '../CartButtonDisplay';
import { useCurrentSession } from '../../../../shared/CurrentSessionProvider';
import { executeWithProgressBar, usePageLoadStageFinished } from '../../../../utils/postponed';

const CartButton = ({ classes, featureSetting, history, onCheckoutButtonClick, onGoToMenuButtonClick }) => {
  const showMobileCartModal = useSelector(state => state.consumer.showMobileCartModal);
  const menuItemCartId = useSelector(state => state.menuItemCart.menuItemCartId);
  const dispatch = useDispatch();
  const restaurant = useRestaurantContext({ keys: ['id', 'onlineOrderDefaultUrl', 'onlineOrderCateringUrl'] });

  const menuItemCartVariables = useMenuItemCartVariables({ restaurantId: restaurant.id });
  const { cartType, orderingEventId } = menuItemCartVariables;

  const currentSession = useCurrentSession();
  const firstSessionLoaded = usePageLoadStageFinished('firstSessionLoaded');
  if (!cartType || !firstSessionLoaded) {
    return null;
  }

  const orderingPageUrl = getOrderingPageUrl({ cartType, restaurant });
  if (!orderingPageUrl && !menuItemCartId) {
    return null;
  }
  const menuItemCart = currentSession.pendingMenuItemCart;
  if (!menuItemCart) {
    return null;
  }

  return (
    <React.Fragment>
      <CartButtonDisplay
        classes={classes}
      />
      <Drawer
        anchor="right"
        open={showMobileCartModal}
        onClose={() => executeWithProgressBar(() => dispatch(setShowMobileCartModal(false)))}
        classes={{
          paper: classes.drawer,
        }}
      >
        <CartButtonSummary
          classes={classes}
          closeShowCartModal={() => {
            executeWithProgressBar(() => {
              dispatch(setShowMobileCartModal(false));
            });
          }}
          featureSetting={featureSetting}
          onCheckoutButtonClick={() => {
            executeWithProgressBar(() => {
              dispatch(setShowMobileCartModal(false));
              if (orderingEventId) {
                onCheckoutButtonClick();
              } else {
                const href = menuItemCart.isSubmitted || menuItemCart.selectedMenuItems.length === 0 ? undefined :
                  `${orderingPageUrl}#checkout?location=${menuItemCart.location.slug}`;
                history.push(href);
              }
            });
          }}
          onGoToMenuButtonClick={() => {
            executeWithProgressBar(() => {
              dispatch(setShowMobileCartModal(false));
              if (orderingEventId) {
                onGoToMenuButtonClick();
              } else {
                let href = `${orderingPageUrl}#menu?location=${menuItemCart.location.slug}`;
                if (menuItemCart.selectedMenuItems.length === 0) {
                  href = `${orderingPageUrl}#getting-started?location=${menuItemCart.location.slug}`;
                }
                history.push(href);
              }
            });
          }}
        />
      </Drawer>
    </React.Fragment>
  );
};

CartButton.defaultProps = {
  onCheckoutButtonClick: null,
  onGoToMenuButtonClick: null,
};

CartButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onCheckoutButtonClick: PropTypes.func,
  onGoToMenuButtonClick: PropTypes.func,
  scrolledHeader: PropTypes.bool.isRequired,
};

export default compose(
  withWindowContext,
  mapProps(({ window, ...props }) => ({
    ...props,
    scrolledHeader: window.scrolledHeader,
  })),
  withRouter,
  withStyles(styles),
)(CartButton);
