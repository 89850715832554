import React, { useState, useMemo, useCallback, type ChangeEvent, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';

const INPUT_DEBOUNCE_DELAY = 500;
const INTERRUPT_DEBOUNCE_DELAY = 100;

let whenNoDebouncing: Promise<void> | null = null;
let resolveWhenNoDebouncing: (() => void) | null = null;

// Resolves when no new user input is coming for INPUT_DEBOUNCE_DELAY + INTERRUPT_DEBOUNCE_DELAY ms.
// When |whenNoDebouncingInTextFields| resolves, the form can submit.
export const whenNoDebouncingInTextFields = (): Promise<void> => whenNoDebouncing || Promise.resolve();

// Call to |stopDebouncingAfterDelay| happens simultaneously with |debouncedOnChange| in TextField.
// This guarantees that |stopDebouncing| will be called after the last user input across all TextFields.
const stopDebouncingAfterDelay = debounce(
  stopDebouncing,
  // Taking extra delay after INPUT_DEBOUNCE_DELAY allows form to update state before resolving |whenNoDebouncingInTextFields|.
  // This extra delay prevents races.
  INPUT_DEBOUNCE_DELAY + INTERRUPT_DEBOUNCE_DELAY,
);

// This will freeze for submission until user input stops coming.
const startDebouncing = (): void => {
  stopDebouncingAfterDelay();
  if (whenNoDebouncing === null) {
    whenNoDebouncing = new Promise((resolve) => {
      resolveWhenNoDebouncing = resolve;
    });
  }
};

// This will allow form to submit.
function stopDebouncing(): void {
  stopDebouncingAfterDelay.cancel();
  resolveWhenNoDebouncing?.();
  resolveWhenNoDebouncing = null;
  whenNoDebouncing = null;
}

interface TextFieldProps {
  onInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const withTextFieldDebouncer = (TextField: React.ComponentType<TextFieldProps>) => {
  const TextFieldDebouncerComponent = ({
    onInput = undefined,
    onChange = undefined,
    value: valueGlobal = undefined,
    ...props
  }: TextFieldProps) => {
    const [previousValueGlobal, setPreviousValueGlobal] = useState<string | undefined>(valueGlobal);

    const [valueLocal, setValueLocal] = useState<string | undefined>(valueGlobal);

    const inputRef = useRef<HTMLInputElement>(null);

    // Debouncing text fields is only safe when submit button can handle the updates.
    // Some forms have custom submit buttons. Fixing all of them is a lot of work.
    // So we only debounce text fields when submit button is the default one (data-testid="submit-group-button").
    const shouldDebounceRef = useRef<boolean>(false);
    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      const form = inputRef.current.closest('form');
      if (form) {
        shouldDebounceRef.current = form.querySelector('button[data-testid="submit-group-button"]') !== null;
      }
    }, []);

    // Using refs to prevent passing |onInput| and |onChange| to useMemo dependencies.
    // When |onInput| or |onChange| props change, useMemo will NOT create new debounced functions.
    // This allows debouncing to work in such cases.
    const onInputRef = useRef<typeof onInput>(onInput);
    onInputRef.current = onInput;
    const onChangeRef = useRef<typeof onChange>(onChange);
    onChangeRef.current = onChange;

    const debouncedOnInput = useMemo(
      () => debounce((event: ChangeEvent<HTMLInputElement>) => {
        onInputRef.current?.(event);
      }, INPUT_DEBOUNCE_DELAY),
      [],
    );

    const debouncedOnChange = useMemo(
      () => debounce((event: ChangeEvent<HTMLInputElement>) => {
        onChangeRef.current?.(event);
        setPreviousValueGlobal(event.target.value);
      }, INPUT_DEBOUNCE_DELAY),
      [],
    );

    // Forms can change |valueGlobal| prop. In that case we discard user input favoring |valueGlobal|.
    if (valueGlobal !== previousValueGlobal && valueGlobal !== valueLocal) {
      setPreviousValueGlobal(valueGlobal);
      // Even though discarding user input may seem dangerous, in that case it's not a big deal, because forms
      // usually respond to user input faster than people can type.
      // Debouncing also decreases the probability of user input being discarded.
      setValueLocal(valueGlobal);
      debouncedOnInput.cancel();
      debouncedOnChange.cancel();
      startDebouncing();
    }

    const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      if (!shouldDebounceRef.current) {
        onInput?.(event);
        return;
      }
      event.persist(); // preserve event.target.value
      debouncedOnInput(event);
    }, [debouncedOnInput, onInput]);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      if (!shouldDebounceRef.current) {
        onChange?.(event);
        return;
      }
      event.persist(); // preserve event.target.value
      const newValue = event.target.value;
      startDebouncing();
      setValueLocal(newValue);
      debouncedOnChange(event);
    }, [debouncedOnChange, onChange]);

    return (
      <TextField
        {...props}
        inputRef={inputRef}
        value={shouldDebounceRef.current ? valueLocal : valueGlobal}
        onInput={handleInput}
        onChange={handleChange}
      />
    );
  };

  return TextFieldDebouncerComponent;
};

export default withTextFieldDebouncer;
