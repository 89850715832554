import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '~/lazy_apollo/client';
import { Provider } from 'react-redux';
import { StaticRouter, Route, Switch } from 'react-router-dom';
import 'intl';
import 'intl/locale-data/jsonp/en-US';
import { IntlProvider } from 'react-intl';

import { setErrorHandler, setSSRCache } from '@shakacode/use-ssr-computation.runtime';
import { setAppStaticProps } from '~/utils/appStaticProps';
import { AnimatedBrowserRouter } from '~/shared/AnimatedBrowserRouter';
import { isSSR } from '../utils/dom';
import { addPropsToChild } from '../utils/react';
import { captureSentryMessage } from '../utils/sentry';
import { initApollo } from '../utils/initApollo';
import { useServerSideQuerySubtle } from '../utils/apolloOptimizations';
import { initConsumerStore } from '../utils/initStore';
import { DraftModeContext, ServerSideMemoContext } from '../utils/initContext';
import restaurantQuery from '../libs/gql/queries/restaurants/restaurantQuery.gql';

import { setupVisualRegressionsMode } from '../utils/visualRegressionsMode';
import ConsumerContainer from './ConsumerContainer';
import { setApolloClient } from '../utils/apolloClient';
import { handleSSRComputationError } from '../lazy_apollo/ssrComputationUtils';
import OrderingApp from '../orderingApp/OrderingApp.imports-loadable';
import { AHLevelProvider } from './shared/AccessibleHeading';
import { useConsumerSSRDisabler } from '../utils/testDataLogs';
import { ScreenReaderModeProvider } from '../utils/screenReaderMode/useScreenReaderMode';

setErrorHandler(handleSSRComputationError);

const RestaurantQuery = ({ children, restaurantId, draftMode }) => {
  const { data } = useServerSideQuerySubtle(
    restaurantQuery,
    {
      variables: {
        draftMode,
        restaurantId,
      },
    },
  );

  if (!data) {
    return null;
  }

  return addPropsToChild(children, { data });
};

const Router = ({ requestPath, children }) => {
  if (isSSR) {
    return (
      <StaticRouter location={requestPath} context={{}}>
        {children}
      </StaticRouter>
    );
  }

  return (
    <AnimatedBrowserRouter>
      {children}
    </AnimatedBrowserRouter>
  );
};

const ConsumerApp = (props) => {
  const locale = 'en'; // TODO: Specify logic to get current language for restaurant or session

  if (process.env.IS_INSTRUMENTED_BUILD) {
    setupVisualRegressionsMode(props.visualRegressionsMode, props.env);
  }

  setAppStaticProps(props);

  // Artificial delay is necessary for testing the sensitivity of CircleCI performance pipelines.
  if (props.artificialDelayMs > 0) {
    if (props.env !== 'production_local') {
      captureSentryMessage('[POPMENU] Artificial Delay is enabled. This is not allowed in production!!!');
    }
    const now = Date.now();
    let i = 0;
    while (now + props.artificialDelayMs > Date.now()) {
      i += 1;
    }
    console.log(`[POPMENU] Artificial ${props.artificialDelayMs}ms task looped ${i} times.`);
  }

  // Apollo
  // Server-side, Apollo client is initialized at a higher level so that queries can be extracted
  // Client-side, Apollo client is hydrated with state extracted from the server results
  const client = props.apolloClient || initApollo({
    operationStoreEnabled: props.operationStoreEnabled,
    ssr: isSSR,
    url: props.graphqlUrl,
  });
  // TODO: Remove this after adding proper methods to lazy load apollo
  setApolloClient(client);

  // Loads Apollo Client messages for errors that are raised only in a development environment.
  if (process.env.NODE_ENV !== 'production') {
    console.log('[POPMENU] Apollo Client Error Messages Loaded');
    import(/* webpackChunkName: "apolloClientDev" */ '~/lazy_apollo/client/dev').then(({ loadDevMessages, loadErrorMessages }) => {
      loadDevMessages();
      loadErrorMessages();
    });
  }

  // Redux
  const store = initConsumerStore();

  console.log(`[POPMENU] Init ${isSSR ? 'Server' : 'Client'} ${process.env.IS_INSTRUMENTED_BUILD ? 'with' : 'without'} instrumented JS`);
  if (process.env.IS_INSTRUMENTED_BUILD && typeof window !== 'undefined' && window.Sentry != null) {
    window.Sentry.captureMessage("Instrumented JS and Sentry are running on the same page. It's likely a production environment. This is bad!!! Immediate action is required!!!");
  }

  const draftModeValue = useMemo(() => ({ draftMode: props.draftMode }), [props.draftMode]);
  setSSRCache(isSSR ? props.serverSideMemo : window.__POPMENU_SSR_CACHE__);

  if (process.env.LOG_TEST_DATA_TO_BROWSER_CONSOLE) {
    // LOG_TEST_DATA_TO_BROWSER_CONSOLE is a compile-time variable, so conditional hook is OK.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { appShouldExitEarly } = useConsumerSSRDisabler();
    if (appShouldExitEarly) {
      return null;
    }
  }

  return (
    <Router requestPath={props.requestPath}>
      <ApolloProvider client={client}>
        <IntlProvider locale={locale}>
          <Provider store={store}>
            <ServerSideMemoContext.Provider value={isSSR ? props.serverSideMemo : window.__POPMENU_SSR_CACHE__}>
              <AHLevelProvider>
                <ScreenReaderModeProvider>
                  <Switch>
                    <Route path={props.orderingAppPath}>
                      <OrderingApp
                        basePath={props.orderingAppPath}
                        restaurantId={props.restaurantId}
                        isStandard
                      />
                    </Route>
                    <Route path={props.cateringAppPath}>
                      <OrderingApp basePath={props.cateringAppPath} isCatering restaurantId={props.restaurantId} />
                    </Route>
                    <Route path={`${props.orderingEventAppPath}/:orderingEventSlug`}>
                      <OrderingApp
                        basePath={props.orderingEventAppPath}
                        restaurantId={props.restaurantId}
                        isOrderingEvent
                      />
                    </Route>
                    <Route path="/">
                      <DraftModeContext.Provider value={draftModeValue}>
                        <RestaurantQuery restaurantId={props.restaurantId} draftMode={props.draftMode}>
                          <ConsumerContainer
                            draftMode={props.draftMode}
                            restaurantId={props.restaurantId}
                            desktop={props.desktop}
                            tablet={props.tablet}
                            mobile={props.mobile}
                            prefetchedQueriesNumber={props.prefetchedQueriesNumber}
                          />
                        </RestaurantQuery>
                      </DraftModeContext.Provider>
                    </Route>
                  </Switch>
                </ScreenReaderModeProvider>
              </AHLevelProvider>
            </ServerSideMemoContext.Provider>
          </Provider>
        </IntlProvider>
      </ApolloProvider>
    </Router>
  );
};

ConsumerApp.defaultProps = {
  apolloClient: null,
};

ConsumerApp.propTypes = {
  apolloClient: PropTypes.object,
  desktop: PropTypes.bool.isRequired,
  draftMode: PropTypes.bool.isRequired,
  env: PropTypes.string.isRequired,
  graphqlUrl: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  operationStoreEnabled: PropTypes.bool.isRequired,
  requestPath: PropTypes.string.isRequired,
  restaurantId: PropTypes.number.isRequired,
  tablet: PropTypes.bool.isRequired,
};

export default ConsumerApp;
