import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon, IconButton, Typography } from '@popmenu/common-ui';
import { Edit, ArrowDown, ArrowUp, Copy, Trash, Plus, X as XIcon } from '@popmenu/web-icons';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';

import { findElements, scrollTo, toggleClass } from '../../utils/dom';
import { withIntl } from '../../utils/withIntl';
import { classNames } from '../../utils/withStyles';

import CustomPageSection from './CustomPageSection';
import Grid from '../../shared/Grid';
import Header from '../shared/Header';
import { visualRegressionsMode } from '../../utils/visualRegressionsMode';
import MultiSectionLazyContainer from '../../shared/MultiSectionLazyContainer';
import { AH, AHLevelProvider } from '../shared/AccessibleHeading';
import SROnlyPageHeading from './SROnlyPageHeading';

class CustomPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.customPageRef = React.createRef();
    this.handleParallaxScroll = this.handleParallaxScroll.bind(this);
    this.sectionAction = this.sectionAction.bind(this);
    this.state = {
      removeModal: null,
    };
    this.openRemoveModal = this.openRemoveModal.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    // Add page-specific class to body
    if (typeof document !== 'undefined' && document.body && this.props.slug) {
      toggleClass(document.body, `pm-page-${this.props.slug}`, true);
    }
  }

  componentDidMount() {
    // Delay to allow images and sections to render before scrolling to ID
    if (window.location?.hash) {
      const menuEl = document.getElementById('menu');
      if (menuEl) {
        scrollTo(menuEl);
        menuEl.focus();
      }
      const selector = window.location.hash.split('#')[1] || window.location.hash;
      try {
        const scrollInterval = setInterval(() => {
          const target = document.getElementById(selector);
          if (target) {
            scrollTo(target);
            target.focus();
            clearInterval(scrollInterval);
          }
        }, 800);
      } catch (err) {
        console.log(`[POPMENU] Invalid selector: ${selector}`);
      }
    }

    // Handle parallax header background
    window.addEventListener('scroll', this.handleParallaxScroll);
    this.handleParallaxScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleParallaxScroll);
    clearInterval(this.scrollInterval);

    // Remove page-specific class from body
    if (this.props.slug) {
      toggleClass(document.body, `pm-page-${this.props.slug}`, false);
    }
  }

  sectionAction(action, section, position) {
    window.parent.postMessage({ page: this.props.id, position, section, sectionAction: action }, '*');
  }

  handleParallaxScroll() {
    const { headerImageSetting } = this.props;
    if (headerImageSetting === 'parallax' || headerImageSetting === 'fixed') {
      const headerImage = findElements('.pm-header-bg');
      let scrolled = window.pageYOffset;
      if (headerImageSetting === 'parallax') {
        scrolled *= 0.2;
      }
      for (let i = 0; i < headerImage.length; i += 1) {
        headerImage[i].style.transform = `translateY(${(scrolled)}px)`;
      }
    }
  }

  openRemoveModal(id) {
    this.setState({ removeModal: id });
  }

  closeRemoveModal() {
    this.setState({ removeModal: null });
  }

  previewControlClass(sectionId) {
    if (sectionId === this.props.activeSection?.id) {
      return 'preview-section-container-activable';
    }
    return 'preview-section-container-hoverable';
  }

  render() {
    const { removeModal } = this.state;

    const pageSections = this.props.sections.filter(section => section.isEnabled);
    const hasMenuSection = pageSections.some(section => section.sectionType === 'menu_section');
    const hasAnnouncementSection = pageSections.some(section => section.sectionType === 'announcement_section');
    // Visual Regresssions: We add blank space in visreg for multiple reasons:
    // * Better resemblance to the actual page. Custom pages sections always have something above and below them. Rendering without a blank space makes it a special case.
    // * Aesthetical. It's harder to see if a section looks good when there is no padding.
    let shouldRenderBlankSpaceForVisReg = false;
    if (process.env.IS_INSTRUMENTED_BUILD && visualRegressionsMode) {
      shouldRenderBlankSpaceForVisReg = !visualRegressionsMode.startsWith('full_page');
    }

    return (
      <div
        ref={this.customPageRef}
        className={classNames(
          'pm-custom-page-wrap',
          this.props.isHomePage ? 'pm-custom-home-wrap' : null,
          this.props.slug ? `pm-custom-page-${this.props.slug}` : null,
        )}
      >
        {this.props.isHeaderEnabled && (
          <Header
            backgroundColor={this.props.heroBackgroundColor}
            customHref={this.props.customHref}
            customPageId={this.props.id}
            heading={this.props.name}
            headingCase={this.props.headerFontCase && this.props.headerFontCase.split('hfc_')[1]}
            heroImages={this.props.heroImages.map(heroImage => ({
              alt: heroImage.headerImageAlt,
              imageUrl: heroImage.imageUrl,
            }))}
            headerBgFullHeight={this.props.headerBgFullHeight}
            headerLinkText={this.props.headerLinkText}
            headerLinkCustomPageId={this.props.headerLinkCustomPageId}
            headerTransitionTiming={this.props.headerTransitionTiming}
            headerTransitionType={this.props.headerTransitionType}
            linkType={this.props.linkType}
            isExternal={this.props.isExternal}
            logoUrl={this.props.heroLogoUrl}
            logoAspectRatio={this.props.heroLogoUploadedPhoto ? (this.props.heroLogoUploadedPhoto.width / this.props.heroLogoUploadedPhoto.height) : Infinity}
            logoAlt={this.props.heroLogoAlt}
            showSkipToPageContent={this.props.isHomePage}
            subheading={this.props.subheading}
            videoCloudflareId={this.props.heroVideoCloudflareId}
            videoThumbnailUrl={this.props.heroVideoThumbnailUrl}
            videoUrl={this.props.heroVideoUrl}
            headerLinkUrl={this.props.headerLinkUrl}
          />
        )}
        {
          (!this.props.isHeaderEnabled || !this.props.name) && (
            <SROnlyPageHeading url={this.props.url} />
          )
        }
        <AHLevelProvider>
          {this.props.isPreview && this.props.sections.length === 0 && (
            <div
              className={'preview-section-container-hoverable no-click'}
              style={{ position: 'relative' }}
            >
              <Button
                style={{
                  bottom: this.props.isHeaderEnabled ? '-18px' : '-40px',
                  textTransform: 'none',
                }}
                startIcon={<Plus />}
                variant="contained"
                onClick={() => this.sectionAction('add', null, 0)}
                className="preview-section-container-hoverable__add-btn-single"
              >{this.props.t('consumer.preview_add_section')}
              </Button>
            </div>
          )}
          {shouldRenderBlankSpaceForVisReg && <div style={{ height: 100, opacity: 0 }} />}
          <MultiSectionLazyContainer
            // Switch between two ways of calculating |firstHiddenSection| value:
            // - (visibleSectionsCount == null): old one which based on assumption uses fixed values
            // - (visibleSectionsCount != null): dynamic which uses number of visible sections for calculation
            firstHiddenSection={(
              this.props.visibleSectionsCount != null ?
                this.props.visibleSectionsCount :
                ((this.props.isHeaderEnabled && !hasAnnouncementSection) || !this.props.isHeaderEnabled ? 1 : 2)
            )}
          >
            {this.props.sections.filter(section => section.isEnabled).map((section, i) => {
              const first = section.position === 0;
              const last = section.position === this.props.sections.length - 1;
              return (
                <div
                  key={section.id}
                  className={classNames(
                    `wb-section-wrapper-cp${this.props.id}-sec${section.id}`,
                    this.props.isPreview ? `${this.previewControlClass(section.id)} no-click` : null,
                  )}
                  style={{ position: 'relative' }}
                >
                  {this.props.isPreview && (
                    <Button
                      style={{ textTransform: 'none' }}
                      startIcon={<Plus />}
                      variant="contained"
                      onClick={() => this.sectionAction('add', null, section.position)}
                      className={`${this.previewControlClass(section.id)}__add-btn`}
                    >{this.props.t('consumer.preview_add_section')}
                    </Button>
                  )}
                  {
                    this.props.isPreview && (
                      <div className="preview-section-container-controls">
                        <Modal
                          open={removeModal === section.id}
                          onClose={this.closeRemoveModal}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                        >
                          <Paper className={'preview-section-controls-modal'}>
                            <Grid container>
                              <Grid item>
                                <AH typography style={{ fontWeight: 'bold' }} variant="h6">
                                  {this.props.t('consumer.preview_controls_modal_confirm')}
                                </AH>
                              </Grid>
                              <Grid item>
                                <Typography gutterBottom>{this.props.t('consumer.preview_controls_modal_message')}</Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  color="secondary"
                                  variant="outlined"
                                  onClick={this.closeRemoveModal}
                                >{this.props.t('consumer.preview_controls_modal_cancel')}
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    this.closeRemoveModal();
                                    this.sectionAction('delete', section.id);
                                  }}
                                >{this.props.t('consumer.preview_controls_modal_confirm')}
                                </Button>
                              </Grid>
                            </Grid>
                            <IconButton
                              className="close-modal-icon"
                              size="small"
                              onClick={this.closeRemoveModal}
                            >
                              <Icon icon={XIcon} />
                            </IconButton>
                          </Paper>
                        </Modal>
                        <ArrowDown
                          style={{ fontSize: 24 }}
                          className={last && 'ctrl-disabled'}
                          onClick={() => !last && this.sectionAction('moveDown', section.id)}
                        />
                        <ArrowUp
                          style={{ fontSize: 24 }}
                          className={first && 'ctrl-disabled'}
                          onClick={() => !first && this.sectionAction('moveUp', section.id)}
                        />
                        <Copy onClick={() => this.sectionAction('duplicate', section.id)} />
                        <Edit onClick={() => this.sectionAction('edit', section.id)} />
                        <Trash onClick={() => this.openRemoveModal(section.id)} />
                      </div>
                    )
                  }
                  <CustomPageSection
                    i={i}
                    customPageId={this.props.id}
                    customPagePath={this.props.url}
                    isDraft={this.props.__typename === 'CustomPageDraft'}
                    isHeaderEnabled={this.props.isHeaderEnabled}
                    editSection={() => this.sectionAction('edit', section.id)}
                    restaurant={{
                      id: this.props.restaurantId,
                    }}
                    hasMenuSection={hasMenuSection}
                    visibleSectionsCount={this.props.visibleSectionsCount}
                    {...section}
                  />
                  {this.props.isPreview && (
                    <Button
                      style={{ textTransform: 'none' }}
                      startIcon={<Plus />}
                      variant="contained"
                      onClick={() => this.sectionAction('add', null, section.position + 1)}
                      className={`${this.previewControlClass(section.id)}__add-btn`}
                    >{this.props.t('consumer.preview_add_section')}
                    </Button>
                  )}
                </div>
              );
            })}
          </MultiSectionLazyContainer>
          {shouldRenderBlankSpaceForVisReg && <div style={{ height: 400, opacity: 0 }} />}
        </AHLevelProvider>
      </div>
    );
  }
}

CustomPage.defaultProps = {
  headerBgFullHeight: false,
  headerFontCase: null,
  headerLinkCustomPageId: null,
  headerLinkText: null,
  headerTransitionTiming: null,
  headerTransitionType: null,
  heroBackgroundColor: null,
  heroImages: [],
  heroLogoAlt: null,
  heroLogoUrl: null,
  heroVideoCloudflareId: null,
  heroVideoThumbnailUrl: null,
  heroVideoUrl: null,
  isExternal: false,
  isHomePage: false,
  name: null,
  slug: null,
  subheading: null,
  visibleSectionsCount: null,
};

CustomPage.propTypes = {
  __typename: PropTypes.string.isRequired,
  customMetaTitle: PropTypes.string.isRequired,
  headerBgFullHeight: PropTypes.bool,
  headerFontCase: PropTypes.string,
  headerImageSetting: PropTypes.string.isRequired,
  headerLinkCustomPageId: PropTypes.number,
  headerLinkText: PropTypes.string,
  headerTransitionTiming: PropTypes.number,
  headerTransitionType: PropTypes.string,
  heroBackgroundColor: PropTypes.string,
  heroImages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  })),
  heroLogoAlt: PropTypes.string,
  heroLogoUrl: PropTypes.string,
  heroVideoCloudflareId: PropTypes.string,
  heroVideoThumbnailUrl: PropTypes.string,
  heroVideoUrl: PropTypes.string,
  id: PropTypes.number.isRequired,
  isExternal: PropTypes.bool,
  isHomePage: PropTypes.bool,
  name: PropTypes.string,
  restaurantId: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    backgroundColor: PropTypes.string,
    backgroundImageUrl: PropTypes.string,
    customHtml: PropTypes.string,
    id: PropTypes.number,
    sectionType: PropTypes.string,
  })).isRequired,
  slug: PropTypes.string,
  subheading: PropTypes.string,
  url: PropTypes.string.isRequired,
  visibleSectionsCount: PropTypes.number,
};

export default connect(state => ({ activeSection: state.consumer.activeSection, isPreview: state.consumer.isPreview }))(withIntl(CustomPage));
