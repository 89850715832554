import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { AH } from '~/consumer/shared/AccessibleHeading';
import { useScreenReaderMode } from './useScreenReaderMode';

const loadMoreContentStyles: React.CSSProperties = {
  left: 0,
  position: 'fixed',
  top: 0,
};

// This "button" is visible to screen readers only.
// For more context open some Consumer App page and enable VoiceOver headings menu.
export const EnableScreenReaderModeButton = () => {
  const { enableScreenReaderMode } = useScreenReaderMode();

  const handleFocus = useCallback(() => {
    enableScreenReaderMode?.();
  }, [enableScreenReaderMode]);

  return (
    <AH
      variant="h2"
      className="sr-only"
      tabIndex={0}
      onFocus={handleFocus}
      style={loadMoreContentStyles}
    >
      <span role="link">
        <FormattedMessage
          id="screen_readers.load_more_content"
          defaultMessage="Load More Content"
        />
      </span>
    </AH>
  );
};
