import React from 'react';
import PropTypes from 'prop-types';
import { withIntl } from '../../utils/withIntl';

import { nl2br } from '../../utils/utils';

const LocationAddressDisplay = ({ location, t }) => {
  // Display country if not US
  const renderStateAndCountry = () => {
    const usAddressText = `${location.city}, ${location.state ? `${location.state} ` : ''}${location.postalCode || ''}`;
    if (location.country !== 'US') {
      // Display state and zip code if given
      if (!location.state && !location.postalCode) {
        return (
          <span>{`${location.city}, ${t(`models.restaurant/location.countries.${location.country}`)}`}</span>
        );
      } else {
        return (
          <React.Fragment>
            <span>{`${location.city}${location.state ? `, ${location.state} ` : ''}${location.postalCode ? ` ${location.postalCode}` : ''}`}</span>
            <span>{t(`models.restaurant/location.countries.${location.country}`)}</span>
          </React.Fragment>
        );
      }
    } else {
      return (<span style={{ display: 'block' }}>{usAddressText}</span>);
    }
  };

  return (
    <React.Fragment>
      {nl2br(location.streetAddress)}
      {renderStateAndCountry()}
    </React.Fragment>
  );
};

LocationAddressDisplay.propTypes = {
  location: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withIntl(LocationAddressDisplay);
