import { useState, useCallback, useEffect, useRef } from 'react';
import { visualRegressionsMode } from './visualRegressionsMode';
import { useScrollEventListener } from './useScrollEventListener';
import type { VisibilityOptions } from './isVisible';
import { isVisible } from './isVisible';
import { useScreenReaderMode } from './screenReaderMode/useScreenReaderMode';

type Props = { reset? : boolean } & VisibilityOptions;

export const useScrollObserver = <TElement extends Element = Element>({ reset, rootMargin, threshold, componentsAboveViewportAreVisible }: Props = {}) => {
  const [scrolledTo, setScrolledTo] = useState<boolean>(false);
  const { screenReaderMode } = useScreenReaderMode();
  const scrollObserverRef = useRef<TElement>(null);

  const checkVisibility = useCallback(() => {
    if (!reset && isVisible(scrollObserverRef.current, {
      componentsAboveViewportAreVisible,
      rootMargin,
      threshold,
    })) {
      setScrolledTo(true);
    }
  }, [componentsAboveViewportAreVisible, reset, rootMargin, threshold]);

  useEffect(() => {
    if (reset) {
      setScrolledTo(false);
    }
  }, [reset]);

  useScrollEventListener((scrolledTo || reset) ? undefined : checkVisibility);

  return { scrolledTo: scrolledTo || screenReaderMode || !!visualRegressionsMode, scrollObserverRef };
};
