/* eslint-disable @typescript-eslint/no-restricted-imports -- */
import { useDispatch as useDispatchLib } from 'react-redux';
/* eslint-enable @typescript-eslint/no-restricted-imports */
import type { Dispatch } from 'redux';
import type { RootAction } from '~/orderingApp/types';

export type DispatchType = Dispatch<RootAction>;

/* Typed re-export of react-redux useDispatch */
export const useDispatch: () => DispatchType = useDispatchLib;
