export default theme => ({
  accordionContent: {
    height: '100%',
    padding: theme.spacing(2, 2),
  },
  accordionPanel: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
  accordionSection: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    display: 'flex',
    margin: 'auto',
    position: 'relative',
    width: '100%',
  },
  btnWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    marginTop: theme.spacing(2),
  },
  btnWrapperWithContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  content: {
    height: '100%',
    padding: theme.spacing(2, 0),
  },
  heading: {
    padding: theme.spacing(2, 0),
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      objectFit: 'fill',
      position: 'relative !important',
    },
    height: '100%',
    left: 0,
    position: 'absolute !important',
    top: 0,
    width: '100%',
  },
  imgOverlay: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  imgOverlayText: {
    '&.pm-img-overlay-text': {
      color: 'white',
    },
    color: 'white',
    zIndex: 3,
  },
  imgWrapper: {
    '&:focus-within': {
      outline: '5px auto -webkit-focus-ring-color !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      overflow: 'visible',
      paddingTop: 0,
    },
    height: 0,
    overflow: 'hidden',
    paddingTop: '66.66%',
    position: 'relative',
    width: '100%',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  sectionWithLink: {
    '& img': {
      filter: 'brightness(0.8)',
      transition: '250ms',
    },
    '&:hover': {
      '& img': {
        filter: 'brightness(0.9)',
      },
    },
  },
  sectionWithoutPhoto: {
    alignSelf: 'center',
  },
  videoWrapper: {
    '& .pm-video-poster': {
      height: '100%',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
    },
    '& video': {
      height: '100%',
      left: '0',
      objectFit: 'cover',
      position: 'absolute',
      top: '0',
      width: '100%',
    },
    height: '0',
    overflow: 'hidden',
    paddingTop: '66.66%',
    position: 'relative',
    width: '100%',
  },
});
