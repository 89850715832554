import React, { useMemo } from 'react';
import type { Property } from 'csstype';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  makeStyles,
  StylesProvider as MuiStylesProvider,
  createGenerateClassName as createMuiGenerateClassName,
  withStyles as withMuiStyles,
} from '@material-ui/core/styles';
import type { StyleRulesCallback, WithStylesOptions } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { adminDarkOptions, adminLightOptions } from '@popmenu/admin-ui';

import type { Theme } from '@material-ui/core';
import type { AdminLightOptions } from '~/@types/material-ui';
import { darkenToReadableColor, readableFontColor } from './colors';
import type { PopmenuTheme } from './withTheme';

export { classNames, makeStyles };

export const BASE_Z_INDEX = 1100000;
export const generateClassName = createMuiGenerateClassName();
export const generateDarkClassName = createMuiGenerateClassName({ seed: 'pm-jss-dark' });

// In MUI v4/v5, this is the only property of MuiThemeProviderProps other than `theme`.
// See the type equality test in withStyles.test.ts and update the definition if it fails.
export interface RestMuiThemeProviderProps {
  children: React.ReactNode;
}

interface ThemeProviderProps extends RestMuiThemeProviderProps {
  popmenuTheme?: PopmenuTheme;
}

// Build material-ui theme from provided Theme model or hardcoded AdminApp options
export const ThemeProvider = ({ popmenuTheme = undefined, ...muiProps }: ThemeProviderProps) => {
  const theme: Theme = useMemo(() => {
    if (popmenuTheme) {
      const textAlign = popmenuTheme.menuItemFontAlignment?.split('_')[2] as Property.TextAlign | undefined;
      /* eslint-disable sort-keys */
      return createTheme({
        ...adminLightOptions as AdminLightOptions,
        mixins: {
          highZoomMediaQuery: '@media screen and (min-height: 600px)',
        },
        overrides: {
          MuiInputBase: {
            input: {
              '&::placeholder': {
                opacity: '.75',
              },
            },
          },
          MuiFormLabel: {
            root: {
              color: 'rgba(0,0,0,.65)',
            },
          },
          MuiOutlinedInput: {
            root: {
              '& fieldset': { border: '1px solid rgba(0, 0, 0, 0.5)' },
            },
          },
          MuiRadio: {
            root: {
              '&.Mui-focusVisible': {
                outline: '2px solid',
              },
            },
          },
        },
        // OurThemeOptions in ~/@types/material-ui.d.ts are based on this, not vice versa.
        // Feel free to change that type if the properties here change.
        consumer: {
          followPrompt: {
            backgroundColor: popmenuTheme.primaryColor || '#F04649',
            color: readableFontColor(popmenuTheme.primaryColor || '#F04649'),
          },
          general: {
            errorMessage: {
              color: '#CC0000',
            },
            infoMessage: {
              color: '#0076CB',
            },
            mutedText: {
              // Intentionally barely muted-
              // cannot be any lighter without violating WCAG contrast ratio
              color: 'rgba(0, 0, 0, 0.8)',
            },
            successMessage: {
              color: '#008000',
            },
          },
          giftCards: {
            backgroundColor: '#FFFFFF',
            color: {
              icon: '#EEEEEE',
              primary: '#000000',
              secondary: '#7F7F7F',
            },
          },
          menus: {
            dishCard: {
              description: {
                color: popmenuTheme.menuItemDescriptionFont?.color || '#000000',
                fontFamily: popmenuTheme.menuItemDescriptionFont?.family ?? undefined,
                textAlign,
              },
              name: {
                color: popmenuTheme.menuItemNameFont?.color || '#000000',
                fontFamily: popmenuTheme.menuItemNameFont?.family ?? undefined,
                fontWeight: (popmenuTheme.menuItemNameFont?.weight || 'bold') as Property.FontWeight,
                textAlign,
                textTransform: (popmenuTheme.menuItemNameFont?.case || 'none') as Property.TextTransform,
              },
              featured: {
                backgroundColor: popmenuTheme.featuredDishBgColor || 'rgba(0, 0, 0, 0.05)',
              },
            },
            tabs: {
              borderColor: popmenuTheme.menuHeaderFont?.color || '#000000',
              color: popmenuTheme.menuHeaderFont?.color || '#000000',
            },
          },
          menuItemCart: {
            formInfoSubtitle: {
              color: '#9E9E9E',
            },
            errorMessage: {
              color: '#CC0000',
            },
            successMessage: {
              color: '#008000',
            },
            summary: {
              backgroundColor: '#FFFFFF',
              border: '1px solid #F4F4F4',
              color: {
                icon: '#EEEEEE',
                primary: '#000000',
                secondary: '#7F7F7F',
              },
              disclaimer: {
                color: '#CC0000',
              },
              header: {
                backgroundColor: '#F4F4F4',
              },
              link: {
                color: '#0A4F8A',
              },
            },
          },
          nav: {
            rootAnnouncement: {
              backgroundColor: popmenuTheme.primaryColor || '#F04649',
              color: readableFontColor(popmenuTheme.primaryColor || '#F04649'),
            },
          },
          primaryColorOnWhite: darkenToReadableColor('#FFFFFF', popmenuTheme.primaryColor || '#000000'),
          textBoxSection: {
            fontColor: popmenuTheme.customSectionContentFont?.color ?? undefined,
          },
          useLegacyDishLayout: popmenuTheme.useLegacyDishLayout,
        },
        palette: {
          ...adminLightOptions.palette,
          primary: {
            main: popmenuTheme.primaryColor || '#000000',
          },
          secondary: {
            main: popmenuTheme.secondaryColor || '#000000',
          },
          surface: {
            dark: '#ECF0F1',
            main: '#ECF0F1',
            light: '#FFFFFF',
          },
          type: popmenuTheme.paletteType === 'dark_palette_type' ? 'dark' : 'light',
        },
        typography: {
          fontFamily: [
            popmenuTheme.defaultFont?.family && `"${popmenuTheme.defaultFont.family}"`,
            '"futura-pt"',
            '"Proxima-Nova"',
            'Arial',
            '"sans-serif"',
          ].filter(Boolean).join(','),
        },
      });
      /* eslint-enable sort-keys */
    } else {
      return createTheme({
        ...adminLightOptions,
        // @ts-expect-error TODO: sc-57827 fix in @popmenu/admin-ui
        palette: {
          ...adminLightOptions.palette,
          background: {
            default: adminLightOptions.palette.surface.main,
          },
        },
      });
    }
  }, [popmenuTheme]);

  return (
    <MuiStylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider
        {...muiProps}
        theme={theme}
      />
    </MuiStylesProvider>
  );
};

export const DarkThemeProvider = (muiProps: RestMuiThemeProviderProps) => {
  const theme = createTheme({
    ...adminDarkOptions,
    // @ts-expect-error TODO: sc-57827 fix in @popmenu/admin-ui
    palette: {
      ...adminDarkOptions.palette,
      background: {
        default: adminDarkOptions.palette.surface.main,
      },
      error: {
        main: adminDarkOptions.palette.error.light,
      },
    },
  });
  return (
    <MuiStylesProvider generateClassName={generateDarkClassName}>
      <MuiThemeProvider {...muiProps} theme={theme} />
    </MuiStylesProvider>
  );
};

// Could have more general `styles: Styles<Theme, TProps>`, but we use callbacks everywhere and this simplifies errors.
export function withStyles<TProps extends object>(styles: StyleRulesCallback<Theme, TProps>, options: WithStylesOptions<Theme> = {}) {
  return withMuiStyles(styles, options);
}
