import type { CSSProperties, CreateCSSProperties } from '@material-ui/styles/withStyles';

export const buildCloudflareSrc = (
  cloudflareUrl: string,
  fileName: string,
  width: number,
  dpi: number,
  quality: number,
) => {
  // URL-encode image resizing options since they are not regular querystring params-
  // un-encoded commas trip up some browsers due to separators expected in the srcSet attribute
  // in format srcSrc="url 1x, url 2x, url 3x"
  const params = [
    `width=${width * dpi}`,
    `height=${width * dpi}`,
    'fit=scale-down',
    'format=auto',
    `quality=${quality}`,
  ];
  const paramsStr = fileName.endsWith('.gif') ? 'format=auto' : encodeURIComponent(params.join(','));
  return `${cloudflareUrl}/cdn-cgi/image/${paramsStr}/${fileName}`;
};

interface ResponsiveImageOptions {
  size?: 'lg' | 'sm';
  downscale?: number;
  desktopDownscale?: number;
  quality?: number;
  explicitWidth?: number;
}

const buildSrcSet = (
  baseUrl: string,
  fileName: string,
  { size, downscale = 1, desktopDownscale = 2, quality = 60, explicitWidth }: ResponsiveImageOptions,
) => {
  // 412 - for Lighthouse v5 width, Nexus 5X; 360 - for Lighthouse v6 width, Moto G4
  // https://web.dev/lighthouse-whats-new-6.0/#emulation
  const widths = [80, 160, 320, 360, 412, 480, 768, 1200, 1440, 1920];
  const srcSet = [];

  const sources: { srcs: string[], width: number }[] = [];
  const build = buildCloudflareSrc;

  // Image may take the same width independently of screen size.
  const widthsToIterate = explicitWidth ? [explicitWidth] : widths.filter(width => width >= 320);

  // Cloudflare doesn't support image scaling for gif images.
  if (!fileName.endsWith('.gif')) {
    widthsToIterate.forEach((width) => {
      let pixelWidth = (size === 'lg' || width <= 768 ? width : width / desktopDownscale) / downscale;
      if (!explicitWidth) {
      // Round width to not abuse CDN caches.
        pixelWidth = widths.find(x => x >= pixelWidth) || 1920;
      }

      const dpis = downscale < 1 ? [1] : [1, 2, 3];

      const srcs = dpis.map(dpi => build(baseUrl, fileName, pixelWidth, dpi, quality));

      if (widthsToIterate.length === 1) {
        srcSet.push(srcs.map((src, srcIndex) => (`${src} ${srcIndex + 1}x`)).join(', '));
      } else {
        srcSet.push(`${srcs[0]} ${width}w`);
      }

      sources.push({
        srcs,
        width,
      });
    });
  }

  if (srcSet.length > 1) {
    // img srcset doesn't fully support mixed width&dpi descriptors.
    // You may mix and match the two types of descriptor.
    // You must not, however, provide multiple image candidate strings that specify the same descriptor.
    // This is Valid srcset:
    // "images/team-photo.jpg 1x, images/team-photo-retina.jpg 2x, images/team-photo-full 2048w"
    // This won't work:
    // "images/team-360 360w, images/team-720 2x, images/team-720 720w, images/team-1440 2x"
    // You can have only one 2x descriptor, so specified 2x src should be large enough to handle each screen size.
    const highDpiFallback = build(baseUrl, fileName, size === 'lg' ? 1920 : 1200, 1, 60);
    srcSet.push(`${highDpiFallback} 2x`);
  }

  const legacyBrowsersFallbackSrc = build(baseUrl, fileName, size === 'lg' ? 1200 : 600, 1, 60);
  return {
    legacyBrowsersFallbackSrc,
    sources,
    // 4k screens notice.
    // When the screen is larger than any specified width the browser will fallback to the first image in the srcSet.
    // This is why it's important to reverse the list here.
    srcSet: srcSet.reverse().join(', '),
  };
};

export const buildResponsiveImgProps = (
  cloudflareBaseUrl: string,
  originalSrc: Optional<string>,
  imgOptions: ResponsiveImageOptions,
) => {
  if (!cloudflareBaseUrl || !originalSrc) {
    return { src: originalSrc, srcSet: null };
  }

  let legacyBrowsersFallbackSrc = originalSrc;
  let srcSet;
  let sources;

  // Cloudflare handling takes precedent
  if (originalSrc.startsWith(cloudflareBaseUrl)) {
    let trailingUrl = originalSrc.replace(cloudflareBaseUrl, '');
    let fileName;
    if (trailingUrl.includes('cdn-cgi')) {
      trailingUrl = trailingUrl.replace('/cdn-cgi/image/', '');
      const urlParts = trailingUrl.split('/');
      urlParts.shift();
      fileName = urlParts.join('/');
    } else {
      fileName = trailingUrl;
    }

    // Ensure there's no leading / or double //
    if (fileName && fileName.length > 0) {
      fileName = fileName.split('/').filter(str => str && str.length > 0).join('/');
    }

    // Responsive srcset of different widths per screen size
    ({
      legacyBrowsersFallbackSrc,
      srcSet,
      sources,
    } = buildSrcSet(cloudflareBaseUrl, fileName, imgOptions));
  }

  return {
    legacyBrowsersFallbackSrc,
    sources,
    srcSet,
  };
};

export const buildResponsiveCssUrls = (cssProperty: keyof CSSProperties, ...args: Parameters<typeof buildResponsiveImgProps>) => {
  const { sources } = buildResponsiveImgProps(...args);
  const styles: CreateCSSProperties = {};
  (sources || []).reverse().forEach((source) => {
    const imageSet = source.srcs.map((src, i) => `url("${src}") ${i + 1}x`).join();
    styles[`@media (max-width: ${source.width}px)`] = {
      [cssProperty]: `image-set(${imageSet})`,
      fallbacks: [
        {
          [cssProperty]: `url("${source.srcs[0]}")`,
        },
        {
          [cssProperty]: `-webkit-image-set(${imageSet})`,
        },
      ],
    };
  });
  return styles;
};

const extractImgWidth = (html: string, src: string) => {
  const escapedSrc = src.replace(/\//g, '\\/');
  const widthOnTheRight = new RegExp(`<img[^>]*src="${escapedSrc}"[^>]*width="(\\d+)"`, 'g');
  const widthOnTheLeft = new RegExp(`<img[^>]*width="(\\d+)"[^>]*src="${escapedSrc}`, 'g');

  const widthOnTheRightInCss = new RegExp(`<img[^>]*src="${escapedSrc}"[^>]*width: *(\\d+)px`, 'g');
  const widthOnTheLeftInCss = new RegExp(`<img[^>]*width: *(\\d+)px[^>]*src="${escapedSrc}`, 'g');

  const width = widthOnTheLeft.exec(html) || widthOnTheRight.exec(html) || widthOnTheRightInCss.exec(html) || widthOnTheLeftInCss.exec(html);
  return width?.length === 2 && width[1] != null ? Number.parseInt(width[1], 10) : undefined;
};

export const injectResponsiveImgs = (
  cloudflareBaseUrl: string,
  html: string,
  imgOptions: ResponsiveImageOptions,
) => {
  if (!html) {
    return html;
  }

  // Find all <img src="(?)" /> values
  // RegExp.exec returns an array with matched exp at 0 and captured value at 1
  const imgSrcs: string[] = [];
  const imgRegEx = /<img[^>]*src="([^"]*)"/g;
  let imgSrc;
  do {
    imgSrc = imgRegEx.exec(html);
    if (imgSrc?.[1] != null && imgSrc.length === 2) {
      imgSrcs.push(imgSrc[1]);
    }
  } while (imgSrc);

  // Replace Cloudflare src attribute with better base src, and inject srcset + sizes attributes
  let finalHtml = html;
  imgSrcs.forEach((originalSrc) => {
    const explicitWidth = extractImgWidth(html, originalSrc);
    const { legacyBrowsersFallbackSrc, srcSet } = buildResponsiveImgProps(cloudflareBaseUrl, originalSrc, { explicitWidth, ...imgOptions });
    if (originalSrc !== legacyBrowsersFallbackSrc || srcSet) {
      finalHtml = finalHtml.replace(`src="${originalSrc}"`, `src="${legacyBrowsersFallbackSrc}" srcset="${srcSet}"`);
    }
  });
  return finalHtml;
};
