import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

import { withWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { withPopmenuConfig } from '../../../utils/withPopmenuConfig';
import { withDraftMode, useDraftMode } from '../../../utils/withDraftMode';
import { withRestaurant } from '../../../utils/withRestaurant';
import { classNames, withStyles } from '../../../utils/withStyles';
import customPageSectionStyles from './styles';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';
import { setMenuItemCartBypassPrompt } from '../../../shared/MenuItemCartActions';
import { useServerSideQuerySubtle } from '../../../utils/apolloOptimizations';
import { addPropsToChild } from '../../../utils/react';

import restaurantWithLocationsQuery from '../../../libs/gql/queries/locations/restaurantWithLocationsQuery.gql';

// Most section types should be lazy-loaded via loadable components
import {
  AdvancedFormsSection,
  ArticleSection,
  CalendarEventsView,
  CustomForm,
  EcwidShopSection,
  FacebookFeedSection,
  FeaturedItemsSection,
  FoodtecGiftCardSection,
  FormsiteSection,
  GallerySection,
  GiftCardSection,
  InstagramFeedSection,
  PopmenuInstagramFeedSection,
  LocationSearchSection,
  LocationSection,
  MapSection,
  MenuItemCartContainerV2,
  NextMenuGroup,
  ReviewSection,
  TripleseatSection,
  VideoSection,
  YoutubeSection,
} from './CustomPageSection.imports-loadable';

// Only core section types should be included initial bundle (generic sections with no dependencies)
import Announcement from '../../announcements/Announcement';
import CustomContent from '../../shared/CustomContent';
import Grid from '../../../shared/Grid';
import MultiColumnSection from '../MultiColumnSection';
import NoSsr from '../../../shared/NoSsr';
import TextBoxSection from '../TextBoxSection';
import Transition from '../../../shared/Transition';
import SectionAboutLink from '../SectionAboutLink';
import { visualRegressionsMode } from '../../../utils/visualRegressionsMode';

const CalendarEventsQuery = ({ calendarEventView, locations, maxCalendarEventsDisplayed, sectionId, restaurant }) => {
  const draftMode = useDraftMode();

  useEffect(() => {
    const refetch = () => null;
    if (draftMode) {
      const messageEventListener = (message) => {
        if (message?.data?.__typename === 'CustomPageDraft') {
          refetch();
        }
      };
      window.addEventListener('message', messageEventListener);
      return () => window.removeEventListener('message', messageEventListener);
    }
    return () => { };
  }, [draftMode]);

  return (
    <React.Fragment>
      <LocationsQuery
        restaurantId={restaurant.id}
        locationsIds={locations}
      >
        <CalendarEventsView
          sectionId={sectionId}
          draftMode={draftMode}
          calendarEventView={calendarEventView}
          locations={locations}
          restaurant={restaurant}
          maxCalendarEventsDisplayed={maxCalendarEventsDisplayed}
        />
      </LocationsQuery>
    </React.Fragment>
  );
};

// Adds `locations` prop to its children.
const LocationsQuery = ({ children, locationsIds, restaurantId }) => {
  const draftMode = useDraftMode();
  const { loading, refetch, data } = useServerSideQuerySubtle(restaurantWithLocationsQuery, { ssr: true, variables: { restaurantId } });

  // For web builder.
  useEffect(() => {
    if (draftMode) {
      const messageEventListener = (message) => {
        if (message?.data?.__typename === 'CustomPageDraft') {
          refetch();
        }
      };
      window.addEventListener('message', messageEventListener);
      return () => window.removeEventListener('message', messageEventListener);
    }
    return () => { };
  }, [draftMode, refetch]);
  const locations = useMemo(() => {
    const ids = locationsIds?.map(location => location.id) || [];
    return data?.restaurant?.locations?.filter(location => ids.includes(location.id));
  }, [data, locationsIds]);

  if (loading) return <Skeleton height="100vh" width="100%" />;
  if (!data) return null;

  return addPropsToChild(children, { locations });
};

class CustomPageSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.clickEvent = this.clickEvent.bind(this);
  }

  componentDidMount() {
    this.props.setMenuItemCartBypassPrompt(false);
    if (this.props.draftMode) {
      this.target = document.getElementsByClassName(`wb-section-wrapper-cp${this.props.customPageId}-sec${this.props.id}`)[0];
      if (this.target) this.target.addEventListener('click', this.clickEvent);
    }
  }

  clickEvent(e) {
    if (e?.path?.some(el => el.className === 'preview-section-container-controls')) return;
    window.parent.postMessage({ page: this.props.customPageId, section: this.props.id, sectionAction: 'edit' }, '*');
  }

  renderAdvancedForms() {
    return <AdvancedFormsSection {...this.props} />;
  }

  renderMigratedAdvancedForms() {
    return (
      <div className="pm-custom-form" id="form">
        <AdvancedFormsSection {...this.props} />
      </div>
    );
  }

  renderAnnouncement() {
    if (this.props.restaurant.featuredAnnouncementsCount === 0) {
      return null;
    }
    return (
      <Announcement
        {...this.props}
      />
    );
  }

  renderCalendarEvent() {
    return (
      <CalendarEventsQuery
        calendarEventView={this.props.calendarEventView ? this.props.calendarEventView : this.props.restaurant.calendarEventView}
        locations={this.props.locations}
        maxCalendarEventsDisplayed={this.props.maxCalendarEventsDisplayed}
        restaurant={this.props.restaurant}
        sectionId={this.props.id}
      />
    );
  }

  renderCustom() {
    if (!this.props.customHtml && !this.props.showTextBoxLink) {
      return null;
    }

    return (
      <div className="fr-view">
        { this.props.customHtml && <CustomContent id={`pm-custom-section-${this.props.id}`} html={this.props.customHtml} />}
        { this.props.showTextBoxLink && <SectionAboutLink {...this.props} /> }
      </div>
    );
  }

  renderCustomEmbed() {
    const checkSumForceRender = new Date().getMilliseconds();
    return (
      <div className="custom-embed">
        <NoSsr>
          <CustomContent
            fixFrameHeight
            html={`<iframe class="custom-embed-frame" key=${checkSumForceRender} frameborder="0" src="/custom-embeds/${this.props.id}?draft=${!!this.props.draftMode}" width="100%"></iframe>`}
            id={`custom-embed-${this.props.id}`}
          />
        </NoSsr>
      </div>
    );
  }

  renderEcwid() {
    return (
      <EcwidShopSection
        {...this.props}
      />
    );
  }

  renderFeaturedItems() {
    return (
      <FeaturedItemsSection {...this.props} />
    );
  }

  renderFacebook() {
    return (
      <FacebookFeedSection
        {...this.props}
      />
    );
  }

  renderForm() {
    if (this.props.customForm) {
      return (
        <div id="form">
          <CustomForm {...this.props.customForm} />
        </div>
      );
    }
    return null;
  }

  renderFormsite() {
    return (
      <FormsiteSection
        {...this.props}
      />
    );
  }

  renderLocationSearch() {
    return (
      <LocationsQuery restaurantId={this.props.restaurant.id} locationsIds={this.props.locations}>
        <LocationSearchSection
          displayLocationStateHeading={this.props.displayLocationStateHeading}
          showLocationCustomButtons={this.props.showLocationCustomButtons}
          showDirectionsButton={this.props.showDirectionsButton}
          defaultZoomLevel={this.props.defaultZoomLevel}
          locationContent={this.props.locationContent}
          locationSectionLayout={this.props.locationSectionLayout}
          mapTheme={this.props.mapTheme}
          heading={this.props.sectionHeading}
          restaurant={this.props.restaurant}
        />
      </LocationsQuery>
    );
  }

  renderLocation() {
    return (
      <LocationsQuery restaurantId={this.props.restaurant.id} locationsIds={this.props.locations}>
        <LocationSection
          heading={this.props.sectionHeading}
        />
      </LocationsQuery>
    );
  }

  renderMap() {
    return (
      <LocationsQuery restaurantId={this.props.restaurant.id} locationsIds={this.props.locations}>
        <MapSection
          defaultZoomLevel={this.props.defaultZoomLevel}
          mapTheme={this.props.mapTheme}
          restaurant={this.props.restaurant}
        />
      </LocationsQuery>
    );
  }

  renderMenu() {
    return (
      <div id={this.props.menus.length === 1 ? 'menu' : 'menus'} tabIndex="-1">
        <NextMenuGroup menus={this.props.menus} />
      </div>
    );
  }

  renderMultiColumnSection() {
    return (
      <MultiColumnSection sections={this.props.sectionColumns} {...this.props} />
    );
  }

  renderPopmenuInstagramFeed() {
    return (
      <PopmenuInstagramFeedSection
        isInstagramFeedEnabled={this.props.restaurant.featureSetting.isInstagramFeedEnabled}
        restaurantName={this.props.restaurant.name}
        restaurantId={this.props.restaurant.id}
        {...this.props}
      />
    );
  }

  renderPress() {
    let { articles } = this.props;

    if (visualRegressionsMode) {
      articles = this.props.articles.slice(0, 9);
    }

    return (
      <div id="press">
        <ArticleSection
          articles={articles}
        />
      </div>
    );
  }

  renderReview() {
    return (
      <ReviewSection restaurantId={this.props.restaurant.id} />
    );
  }

  renderTextBox() {
    return (
      <TextBoxSection
        {...this.props}
      />
    );
  }

  renderTripleseat() {
    return (
      <TripleseatSection
        {...this.props}
      />
    );
  }

  renderVideo() {
    return (
      <VideoSection
        {...this.props}
      />
    );
  }

  renderYoutube() {
    return (
      <YoutubeSection
        {...this.props}
      />
    );
  }

  renderContent() {
    switch (this.props.sectionType) {
      case 'advanced_forms_section':
        return this.renderAdvancedForms();
      case 'announcement_section':
        return this.renderAnnouncement();
      case 'calendar_event_section':
        return this.renderCalendarEvent();
      case 'custom_embed_section':
        return this.renderCustomEmbed();
      case 'custom_section':
        return this.renderCustom();
      case 'ecwid_shop_section':
        return this.renderEcwid();
      case 'facebook_feed_section':
        return this.renderFacebook();
      case 'featured_items_section':
        return this.renderFeaturedItems();
      case 'foodtec_section':
        return <FoodtecGiftCardSection customPageSectionId={this.props.id} />;
      case 'form_section':
        return this.renderForm();
      case 'formsite_section':
        return this.renderFormsite();
      case 'gallery_section':
        return <GallerySection {...this.props} />;
      case 'gift_card_section':
        return <GiftCardSection {...this.props} />;
      case 'location_search_section':
        return this.renderLocationSearch();
      case 'location_section':
        return this.renderLocation();
      case 'map_section':
        return this.renderMap();
      case 'menu_section':
        return this.renderMenu();
      case 'migrated_advanced_forms_section':
        return this.renderMigratedAdvancedForms();
      case 'multi_column_section':
        return this.renderMultiColumnSection();
      case 'online_ordering_section':
        return (
          <MenuItemCartContainerV2
            cartType={this.props.cartType}
            customPagePath={this.props.customPagePath}
            hashRoute={this.props.location.hash || ''}
          />
        );
      case 'powr_instagram_feed_section':
        return <InstagramFeedSection powrInstagramFeedId={this.props.powrInstagramFeedId} sectionId={this.props.id} />;
      case 'popmenu_instagram_feed_section':
        return this.renderPopmenuInstagramFeed();
      case 'press_section':
        return this.renderPress();
      case 'review_section':
        return this.renderReview();
      case 'text_box_section':
        return this.renderTextBox();
      case 'tripleseat_form_section':
        return this.renderTripleseat();
      case 'video_section':
        return this.renderVideo();
      case 'youtube_video_section':
        return this.renderYoutube();
      default:
        return null;
    }
  }

  render() {
    // Prevent displaying empty container for announcement sections
    if (this.props.sectionType === 'announcement_section' && this.props.restaurant.featuredAnnouncementsCount === 0) {
      return null;
    }

    if (visualRegressionsMode && !visualRegressionsMode.startsWith(`custom_page_section_${this.props.i}`) &&
          !visualRegressionsMode.startsWith('full_page')) {
      return null;
    }

    const shouldRenderSectionHeading = !!this.props.sectionHeading && (this.props.sectionType !== 'advanced_forms_section' && this.props.sectionType !== 'migrated_advanced_forms_section');
    const shouldRenderSectionSubheading = !!this.props.sectionSubheading;

    return (
      <React.Fragment>
        <section
          aria-labelledby={this.props.accessibleAltContent ? `section-${this.props.id}-alt` : null}
          id={this.props.slug ? this.props.slug : `section-${this.props.id}`}
          className={classNames(
            'pm-custom-section',
            `pm-custom-section-${this.props.sectionType.replace('_', '-').replace('powr', '')}`, // powr prefix breaks Powr.io script
            this.props.sectionType !== 'menu_section' && this.props.sectionType !== 'online_ordering_section' ? 'fr-view' : null,
            this.props.isFullWidth ? this.props.classes.fullWidth : null,
            this.props.classes.background,
            this.props.classes.sectionContainer,
          )}
          role={this.props.accessibleAltContent ? 'presentation' : null}
          tabIndex="-1"
        >
          <Transition
            transitionTiming={this.props.transitionTiming}
            transitionType={this.props.transitionType}
            waitForScrolled={this.props.i > 1}
            isHeaderEnabled={this.props.isHeaderEnabled}
            hasMenuSection={this.props.hasMenuSection}
            visibleSectionsCount={this.props.visibleSectionsCount}
            sectionIndex={this.props.i}
          >
            <div className={classNames(this.props.isFullWidth ? this.props.classes.fullWidth : 'container')}>
              <Grid container spacing={0} className={'pm-custom-section-wrapper'}>
                {shouldRenderSectionHeading && (
                  <Grid item xs={12}>
                    <AH
                      className="pm-custom-section-heading"
                      variant={(!this.props.isHeaderEnabled && this.props.i === 0) ? 'h1' : 'h2'}
                    >
                      {this.props.sectionHeading}
                    </AH>
                  </Grid>
                )}
                <AHLevelProvider>
                  {shouldRenderSectionSubheading && (
                    <Grid item xs={12}>
                      <AH
                        className="pm-custom-section-subheading"
                        variant={(!this.props.isHeaderEnabled && this.props.i === 0) ? 'h2' : 'h3'}
                      >
                        {this.props.sectionSubheading}
                      </AH>
                    </Grid>
                  )}
                  <AHLevelProvider>
                    <Grid item xs={12} className={`pm-${this.props.sectionType.replace(/_/g, '-')}-wrapper`}>
                      {this.renderContent()}
                    </Grid>
                  </AHLevelProvider>
                </AHLevelProvider>
              </Grid>
            </div>
          </Transition>
        </section>
        {this.props.accessibleAltContent && (
          <div className="sr-only" id={`section-${this.props.id}-alt`}>
            {this.props.accessibleAltContent}
          </div>
        )}
      </React.Fragment>
    );
  }
}

CustomPageSection.defaultProps = {
  accessibleAltContent: null,
  articles: [],
  backgroundColor: null,
  backgroundEffect: null,
  backgroundImageSetting: 'static',
  backgroundImageUrl: null,
  calendarEventView: 'timeline',
  customForm: null,
  customHtml: null,
  defaultZoomLevel: 15,
  draftMode: false,
  editSection: null,
  galleryColumn: null,
  galleryImages: [],
  galleryPadding: null,
  galleryType: 'slider_gallery',
  hasDynamicHeight: false,
  hasMenuSection: false,
  id: 0,
  instagramFeedPhotos: [],
  instagramUserId: null,
  isFullWidth: false,
  isHeaderEnabled: true,
  locationContent: null,
  locations: [],
  locationSectionLayout: 'lls_bottom',
  mapTheme: 'streets',
  maxCalendarEventsDisplayed: null,
  menus: [],
  sectionColumns: [],
  sectionHeading: null,
  showTextBoxLink: false,
  slug: null,
  visibleSectionsCount: null,
};

CustomPageSection.propTypes = {
  accessibleAltContent: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.shape({
    articleTitle: PropTypes.string,
    articleUrl: PropTypes.string,
    id: PropTypes.number,
  })),
  backgroundColor: PropTypes.string,
  backgroundEffect: PropTypes.string,
  backgroundImageSetting: PropTypes.oneOf(['fixed', 'static']),
  backgroundImageUrl: PropTypes.string,
  calendarEventView: PropTypes.string,
  cartType: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  customForm: PropTypes.shape({
    formType: PropTypes.string,
    id: PropTypes.number,
  }),
  customHtml: PropTypes.string,
  customPageId: PropTypes.number.isRequired,
  customPagePath: PropTypes.string.isRequired,
  defaultZoomLevel: PropTypes.number,
  draftMode: PropTypes.bool,
  editSection: PropTypes.func,
  galleryColumn: PropTypes.number,
  galleryImages: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    imageUrl: PropTypes.string,
  })),
  galleryPadding: PropTypes.string,
  galleryType: PropTypes.string,
  hasDynamicHeight: PropTypes.bool,
  hasMenuSection: PropTypes.bool,
  id: PropTypes.number,
  instagramFeedPhotos: PropTypes.arrayOf(PropTypes.shape({
    caption: PropTypes.string,
    id: PropTypes.number,
    photoUrl: PropTypes.string,
  })),
  instagramUserId: PropTypes.number,
  isFullWidth: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
  locationContent: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  locationSectionLayout: PropTypes.string,
  mapTheme: PropTypes.string,
  maxCalendarEventsDisplayed: PropTypes.number,
  menus: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  restaurant: PropTypes.shape({
    calendarEventView: PropTypes.string,
    featuredAnnouncementsCount: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    theme: PropTypes.shape({
      defaultCustomSectionBgColor: PropTypes.string,
    }),
  }).isRequired,
  sectionColumns: PropTypes.arrayOf(PropTypes.shape()),
  sectionHeading: PropTypes.string,
  sectionType: PropTypes.string.isRequired,
  setMenuItemCartBypassPrompt: PropTypes.func.isRequired,
  showTextBoxLink: PropTypes.bool,
  slug: PropTypes.string,
  visibleSectionsCount: PropTypes.number,
};

export default compose(
  connect(state => ({
    bypassPrompt: state.menuItemCart.bypassPrompt,
  }), {
    setMenuItemCartBypassPrompt,
  }),
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    cloudflareBaseUrl: popmenuConfig.cloudflareUrl,
  })),
  withRestaurant,
  withRouter,
  withStyles(customPageSectionStyles),
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
  })),
  withDraftMode,
)(CustomPageSection);
