
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "customFormEntriesAttachment": {
      "id": "custom_form_entries.attachment",
      "defaultMessage": "File Attachment (pdf, doc, docx)"
    },
    "customFormEntriesEmail": {
      "id": "custom_form_entries.email",
      "defaultMessage": "Email"
    },
    "customFormEntriesLocationId": {
      "id": "custom_form_entries.location_id",
      "defaultMessage": "Select a location"
    },
    "customFormEntriesMessage": {
      "id": "custom_form_entries.message",
      "defaultMessage": "Message"
    },
    "customFormEntriesName": {
      "id": "custom_form_entries.name",
      "defaultMessage": "Name"
    },
    "customFormEntriesPhone": {
      "id": "custom_form_entries.phone",
      "defaultMessage": "Phone"
    },
    "customFormEntriesPhoneOptional": {
      "id": "custom_form_entries.phone_optional",
      "defaultMessage": "Phone (optional)"
    },
    "customFormEntriesNotes": {
      "id": "custom_form_entries.notes",
      "defaultMessage": "Notes",
    },
    "customFormEntriesNoteToRestaurant": {
      "id": "custom_form_entries.note_to_restaurant",
      "defaultMessage": "Note to restaurant"
    }
  }
);
export default defaultMessages;

