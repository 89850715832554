import React, { useCallback, useContext, type ComponentType } from 'react';
import type { OptionsObject, ProviderContext, SnackbarKey, SnackbarMessage } from 'notistack';
import { useSnackbar as useSnackbarLib } from 'notistack';
import { IntlContext } from 'react-intl';

import { useDispatch } from '~/utils/redux';
import { buildErrorMessage } from './i18n';

import { switchSnackbar } from '../shared/SnackbarActions';

export { default as SnackbarProvider } from '../shared/SnackbarProvider';

type CustomSnackbarOptions = OptionsObject & { defaultMessage?: string }

export type ShowSnackbar = (message: SnackbarMessage, snackbarOptions?: CustomSnackbarOptions) => void;
export type ShowSnackbarError = (error: unknown, options?: CustomSnackbarOptions) => void;
export type SnackbarProps = {
  closeSnackbar: ProviderContext['closeSnackbar'];
  showSnackbar: ShowSnackbar;
  showSnackbarError: ShowSnackbarError;
}

export const useSnackbar = (): SnackbarProps => {
  const intl = useContext(IntlContext);
  const dispatch = useDispatch();
  const { closeSnackbar, enqueueSnackbar } = useSnackbarLib();

  const showSnackbar: ShowSnackbar = useCallback((message, snackbarOptions = {}) => {
    if (snackbarOptions.key) {
      dispatch(switchSnackbar(snackbarOptions.key));
    }
    enqueueSnackbar(message, { autoHideDuration: 6000, variant: 'success', ...snackbarOptions, onClick: () => closeSnackbar(snackbarOptions.key) });
  }, [enqueueSnackbar, dispatch, closeSnackbar]);

  const showSnackbarError: ShowSnackbarError = useCallback((err, options = {}) => {
    const { defaultMessage, ...snackbarOptions } = options;
    const message = buildErrorMessage({ defaultMessage, err, intl });
    enqueueSnackbar(message, { autoHideDuration: 6000, variant: 'error', ...snackbarOptions, onClick: () => closeSnackbar(snackbarOptions.key) });
  }, [enqueueSnackbar, intl, closeSnackbar]);

  const close = useCallback((key?: SnackbarKey) => {
    if (key) dispatch(switchSnackbar(key));
    closeSnackbar(key);
  }, [dispatch, closeSnackbar]);

  return { closeSnackbar: close, showSnackbar, showSnackbarError };
};

export function withSnackbar<TProps extends SnackbarProps>(Component: ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithSnackbar(props: Omit<TProps, keyof SnackbarProps>) {
    const snackbarProps = useSnackbar();

    return <Component {...props as TProps} {...snackbarProps} />;
  };
}
