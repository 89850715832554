import React from 'react';
import { AH } from '../shared/AccessibleHeading';
import { useRestaurant } from '../../utils/withRestaurant';

const SROnlyPageHeading = (props: { url: string }) => {
  const restaurant = useRestaurant();
  const headingText = props.url === '/' ? restaurant?.name : props.url?.replace(/\//g, ' ').trim();

  return (
    <AH variant="h1" className="sr-only">
      {headingText}
    </AH>
  );
};

export default SROnlyPageHeading;
