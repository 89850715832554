import 'intl-pluralrules';
/* eslint-disable import/no-unresolved */
import messages from 'I18nMessages';
/* eslint-enable import/no-unresolved */
import type { IntlFormatters, IntlShape } from 'react-intl';
import type { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import type { ReactNode } from 'react';
import type { ApolloError } from '~/lazy_apollo/client';
import { asErrorString } from './asError';

type MessageValues = Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
export type TranslateFunction = (key: string, values?: MessageValues) => string;

/* eslint-disable no-param-reassign */
export const t = (intl: IntlFormatters<ReactNode>): TranslateFunction => (key, values = {}) => {
  try {
    let message = messages[key] || Object.values(messages).find(m => (m.id === key));
    if (message === undefined && values.count) {
      key = `${key}.${values.count === 1 ? 'one' : 'other'}`;
      message = messages[key] || Object.values(messages).find(m => (m.id === key));
      values.count = String(values.count);
    }
    if (message) {
      return intl.formatMessage(message, values).replace('%', '');
    } else {
      throw new Error(`I18n invalid key: ${key}`);
    }
  } catch (err) {
    if (typeof window !== 'undefined' && window.Sentry) {
      window.Sentry.captureMessage((err as Error).message);
    } else {
      console.warn(`[POPMENU] i18n error: ${(err as Error).message}`);
    }
  }
  // console.warn(`[POPMENU] Invalid i18n key: ${key}`);
  return key;
};
/* eslint-enable no-param-reassign */

interface BuildErrorMessageArg {
  err: unknown;
  defaultMessage?: string;
  intl?: IntlShape;
  tWithIntl?: (key: string) => string;
}

declare module 'graphql/error' {
  class GraphQLError {
    // Added by our API?
    readonly errorName?: string;

    readonly friendlyMessage?: string;
  }
}

// Can't depend on ApolloError type in '@apollo/client' because it's not returned by all functions in that package
// For example, the `apolloClient.mutate` function returns a `FetchResult` which only includes the `graphQLErrors` property that represents the errors that returned by the GraphQL server
// So, other properties like `message`, `networkError`, etc. are not included in all cases
const isApolloError = (err: unknown): err is Pick<ApolloError, 'graphQLErrors'> => (!!err && typeof err === 'object' && 'graphQLErrors' in err && Array.isArray(err.graphQLErrors));

export const buildErrorMessage = ({ err, defaultMessage = 'failed', intl, tWithIntl }: BuildErrorMessageArg) => {
  let messageKey: string | undefined;
  if (typeof err === 'string') {
    // String
    messageKey = err;
  } else if (isApolloError(err)) {
    // Apollo GQL error object
    messageKey = err.graphQLErrors?.[0]?.message || defaultMessage;
  } else {
    messageKey = asErrorString(err);
  }

  let message: string | undefined;
  if (typeof messageKey !== 'string' || messageKey === 'null') {
    // Default message for invalid args
    message = defaultMessage;
  } else if (messageKey.startsWith('Argument') || messageKey.includes(' ')) {
    // Seems like the messageKey is already friendly
    message = messageKey;
  } else if (tWithIntl) {
    // Wrapped `t` prop provided by a component
    message = tWithIntl(`errors.${messageKey}`);
  } else if (intl) {
    // `intl` prop provided by a component, passed to the raw `t` fn
    message = t(intl)(`errors.${messageKey}`);
  } else {
    message = messageKey;
  }

  // Fallback to API-provided friendly message
  if (message === `errors.${messageKey}` && typeof err !== 'string') {
    if (isApolloError(err)) {
      message = err.graphQLErrors?.[0]?.friendlyMessage || defaultMessage;
    } else {
      message = asErrorString(err);
    }
  }

  if (typeof message !== 'string') {
    return defaultMessage;
  }

  return message;
};
