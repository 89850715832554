import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '~/lazy_apollo/client';

import type { AdminSessionQuery } from '~/libs/gql/queries/users/adminSessionQuery.generated';
import { CurrentSessionDocument } from '~/libs/gql/queries/users/currentSessionQuery.generated';
import {
  CurrentSessionWithMenuItemCartDocument,
  type CurrentSessionWithMenuItemCartQuery,
} from '~/libs/gql/queries/menu_item_carts/sessionWithMenuItemCartQuery.generated';
import type { FunctionComponent } from '~/utils/react';
import { runAfter } from '../utils/postponed';
import { useMenuItemCartVariables } from '../consumer/menus/cart/MenuItemCartHelpers';

const INITIAL_SESSION_STATE: CurrentSession = {
  __typename: 'Session',
  avatarUrl: null,
  calendarEventPops: [],
  id: undefined as unknown as string,
  isAuthyRequired: false,
  isAuthyVerified: false,
  pendingMenuItemCart: {
    __typename: 'MenuItemCart',
    cartType: 'default_cart_type',
    location: {},
    selectedMenuItems: [],
  } as unknown as CurrentSession['pendingMenuItemCart'],
  pops: [],
  reviews: [],
  reviewUpvotes: [],
  user: null,
};

const CurrentSessionContext = /* @__PURE__ */ React.createContext<CurrentSession>({
  ...INITIAL_SESSION_STATE,
});

// When converting clients to TypeScript, use `User` from GQL for properties with this proptype.
export const currentUserShape = /* @__PURE__ */ PropTypes.shape({
  avatarUrl: PropTypes.string,
  birthDay: PropTypes.number,
  birthMonth: PropTypes.number,
  calendarEventPops: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    calendarEventId: PropTypes.number,
    id: PropTypes.number,
    popType: PropTypes.string,
  })),
  createdAt: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  followers: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    favoriteLocation: /* @__PURE__ */ PropTypes.shape({
      id: PropTypes.number,
    }),
    isSmsUnsubscribed: PropTypes.bool,
    isUnsubscribed: PropTypes.bool,
    restaurantId: PropTypes.number,
  })),
  fullDisplayName: PropTypes.string,
  hasAcceptedClientTerms: PropTypes.bool,
  id: PropTypes.number,
  isPendingPassword: PropTypes.bool,
  isRoot: PropTypes.bool,
  lastName: PropTypes.string,
  members: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    id: PropTypes.number,
    memberLevel: PropTypes.string,
    restaurantId: PropTypes.number,
  })),
  onboardingRole: PropTypes.string,
  pendingInvites: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    restaurant: /* @__PURE__ */ PropTypes.shape({
      name: PropTypes.string,
    }),
    token: PropTypes.string,
  })),
  phone: PropTypes.string,
  pops: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    dishableId: PropTypes.number,
    dishableType: PropTypes.string,
    id: PropTypes.number,
    popType: PropTypes.string,
  })),
  reviews: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    content: PropTypes.string,
    dishableId: PropTypes.number,
    dishableType: PropTypes.string,
    id: PropTypes.number,
    restaurant: /* @__PURE__ */ PropTypes.shape({
      id: PropTypes.number,
    }),
  })),
  sfdcContactId: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  userRole: PropTypes.string,
});

// When converting clients to TypeScript, use `PopmenuTheme` for properties with this proptype.
export const currentSessionShape = /* @__PURE__ */ PropTypes.shape({
  avatarUrl: PropTypes.string,
  calendarEventPops: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    calendarEventId: PropTypes.number,
    id: PropTypes.number,
    popType: PropTypes.string,
  })),
  id: PropTypes.string,
  pops: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    dishableId: PropTypes.number,
    dishableType: PropTypes.string,
    id: PropTypes.number,
    popType: PropTypes.string,
  })),
  reviews: /* @__PURE__ */ PropTypes.arrayOf(/* @__PURE__ */ PropTypes.shape({
    content: PropTypes.string,
    dishableId: PropTypes.number,
    dishableType: PropTypes.string,
    id: PropTypes.number,
  })),
  user: currentUserShape,
});

type SessionKind = 'consumer' | 'admin' | undefined;

// Note that CurrentSessionQuery is only different from CurrentSessionWithMenuItemCartQuery
// in missing the optional pendingMenuItemCart property.
// This is checked in the tests, and the definition should be updated to
// `OptionalizedUnion<CurrentSessionQuery['currentSession'] | CurrentSessionWithMenuItemCartQuery['currentSession']>`
// if this changes.
export type ConsumerCurrentSession = CurrentSessionWithMenuItemCartQuery['currentSession'];
export type AdminCurrentSession = AdminSessionQuery['currentSession'];

export type CurrentSession<TSessionKind extends SessionKind = undefined> =
  TSessionKind extends 'consumer' ? ConsumerCurrentSession :
  TSessionKind extends 'admin' ? AdminCurrentSession :
  OptionalizedUnion<ConsumerCurrentSession | AdminCurrentSession>;

export type WithCurrentSessionProps<TSessionKind extends SessionKind = undefined> = {
  currentSession: CurrentSession<TSessionKind>;
};

export function withCurrentSession<TProps extends WithCurrentSessionProps<'consumer'>>(Component: React.ComponentType<TProps>): FunctionComponent<Omit<TProps, keyof WithCurrentSessionProps>>;
export function withCurrentSession<TProps extends WithCurrentSessionProps<'admin'>>(Component: React.ComponentType<TProps>): FunctionComponent<Omit<TProps, keyof WithCurrentSessionProps>>;
export function withCurrentSession<TProps extends WithCurrentSessionProps>(Component: React.ComponentType<TProps>): FunctionComponent<Omit<TProps, keyof WithCurrentSessionProps>>;
export function withCurrentSession<TProps extends WithCurrentSessionProps>(Component: React.ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithCurrentSession(props: Omit<TProps, keyof WithCurrentSessionProps>) {
    return (
      <CurrentSessionContext.Consumer>
        {currentSession => <Component {...props as TProps} currentSession={currentSession} />}
      </CurrentSessionContext.Consumer>
    );
  };
}

export const useCurrentSession = <TSessionKind extends SessionKind = undefined>() => (
  useContext(CurrentSessionContext) as CurrentSession<TSessionKind>
);

export type ConsumerCurrentUser = ConsumerCurrentSession['user'];
export type AdminCurrentUser = AdminCurrentSession['user'];
type NonNullableCurrentUser = OptionalizedUnion<NonNullable<ConsumerCurrentUser | AdminCurrentUser>>;
export type CurrentUser<TSessionKind extends SessionKind = undefined> =
  TSessionKind extends 'consumer' ? ConsumerCurrentUser :
  TSessionKind extends 'admin' ? AdminCurrentUser :
  Optional<NonNullableCurrentUser>;
export type WithCurrentUserProps<TSessionKind extends SessionKind = undefined> = {
  currentUser: CurrentUser<TSessionKind>
};

export const useCurrentUser = <TSessionKind extends SessionKind = undefined>() => (
  useCurrentSession().user as CurrentUser<TSessionKind>
);

export function withCurrentUser<TProps extends WithCurrentUserProps<'consumer'>>(Component: React.ComponentType<TProps>): FunctionComponent<Omit<TProps, keyof WithCurrentUserProps>>;
export function withCurrentUser<TProps extends WithCurrentUserProps<'admin'>>(Component: React.ComponentType<TProps>): FunctionComponent<Omit<TProps, keyof WithCurrentUserProps>>;
export function withCurrentUser<TProps extends WithCurrentUserProps>(Component: React.ComponentType<TProps>): FunctionComponent<Omit<TProps, keyof WithCurrentUserProps>>;
export function withCurrentUser<TProps extends WithCurrentUserProps>(Component: React.ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithCurrentUser(props: Omit<TProps, keyof WithCurrentUserProps>) {
    return (
      <CurrentSessionContext.Consumer>
        {currentSession => <Component {...props as TProps} currentUser={currentSession?.user} />}
      </CurrentSessionContext.Consumer>
    );
  };
}

interface CurrentSessionContextProviderProps {
  children: React.ReactNode;
  value: CurrentSession;
}

// Only for use in analytics
export let currentSessionGlobal: CurrentSession = INITIAL_SESSION_STATE;

export const CurrentSessionContextProvider = ({ children, value }: CurrentSessionContextProviderProps) => {
  currentSessionGlobal = value;
  return (
    <CurrentSessionContext.Provider value={value}>
      {children}
    </CurrentSessionContext.Provider>
  );
};

interface CurrentSessionProviderProps {
  children: React.ReactNode;
  restaurantId?: number;
  withMenuItemCart?: boolean;
}

const CurrentSessionProvider = ({ children, restaurantId = undefined, withMenuItemCart = false }: CurrentSessionProviderProps) => {
  const menuItemCartVariables = useMenuItemCartVariables({ restaurantId });

  const [query, variables] = withMenuItemCart ?
    [CurrentSessionWithMenuItemCartDocument, menuItemCartVariables] :
    [CurrentSessionDocument, {}];

  // Performance tweak: in the first visit session query is skipped until the page is fully loaded.
  const [fetchQuery, { called, data, loading }] = useLazyQuery<WithCurrentSessionProps>(query, { fetchPolicy: 'network-only', variables });

  useEffect(() => {
    void runAfter('firstSessionLoaded', fetchQuery);
  }, [fetchQuery]);

  const currentSession = data?.currentSession || INITIAL_SESSION_STATE;
  const contextValue = useMemo(() => ({
    ...currentSession,
    loading: !called || loading,
    refetch: fetchQuery,
  }), [called, currentSession, loading, fetchQuery]);

  return (
    <CurrentSessionContextProvider value={contextValue}>
      {children}
    </CurrentSessionContextProvider>
  );
};

export default CurrentSessionProvider;
