// Google "VoiceOver headings menu" for more context.
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScreenReaderModeManager } from './ScreenReaderModeManager';

interface ScreenReaderModeContextType {
  screenReaderMode?: boolean;
  enableScreenReaderMode?: () => void;
  onNewHeading?: (top: number, focus: ()=> void) => void;
}

export const ScreenReaderModeContext = React.createContext<ScreenReaderModeContextType>({});

export const ScreenReaderModeProvider = (props: { children: React.ReactNode; }) => {
  const [screenReaderMode, setScreenReaderMode] = React.useState(false);
  const [showPageHasLoadedMessage, setShowPageHasLoadedMessage] = React.useState(false);

  // State is managed externally to avoid unnecessary re-renderings.
  const stateRef = React.useRef(new ScreenReaderModeManager(
    setShowPageHasLoadedMessage,
    setScreenReaderMode,
  ));

  const value = React.useMemo(
    () => ({
      enableScreenReaderMode: stateRef.current.enableScreenReaderMode,
      onNewHeading: stateRef.current.onNewHeading,
      screenReaderMode,
    }),
    [screenReaderMode],
  );

  return (
    <ScreenReaderModeContext.Provider value={value}>
      { screenReaderMode && (
        <div className="sr-only" role="alert" aria-live="assertive">
          <FormattedMessage
            id="screen_readers.loading_more_content"
            defaultMessage="Loading more content"
          />
        </div>
      )}
      { showPageHasLoadedMessage && (
        <div className="sr-only" role="alert" aria-live="assertive">
          <FormattedMessage
            id="screen_readers.page_loaded"
            defaultMessage="Page has loaded"
          />
        </div>
      )}
      {props.children}
    </ScreenReaderModeContext.Provider>
  );
};

export const useScreenReaderMode = () => useContext(ScreenReaderModeContext);
