import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDispatch as useDispatchOrderingApp } from '~/orderingApp/hooks';
import { Button, Icon } from '@popmenu/common-ui';
import { ShoppingBag } from '@popmenu/web-icons';
import { useHistory } from 'react-router-dom';
import { useFeatureFlags } from '~/utils/featureFlagsContext';

import { setShowMobileCartModal } from '../../../shared/ConsumerActions';
import { useCurrentSession } from '../../../shared/CurrentSessionProvider';
import { executeWithProgressBar } from '../../../utils/postponed';

const CartButtonDisplay = ({ classes }) => {
  const { isFeatureActive } = useFeatureFlags();
  const isOrderingAppActive = isFeatureActive('popmenu_ordering_app');
  const history = useHistory();

  const { refetch, loading, pendingMenuItemCart: menuItemCart } = useCurrentSession();
  const dispatch = useDispatch();
  const orderingDispatch = useDispatchOrderingApp();

  useEffect(() => {
    if (!loading && (menuItemCart.isSubmitted || menuItemCart.isTabClosedConsumer)) {
      refetch();
    }
  }, [refetch, loading, menuItemCart.isSubmitted, menuItemCart.isTabClosedConsumer]);

  let quantity = 0;
  menuItemCart.selectedMenuItems.forEach((selectedMenuItem) => {
    quantity += (selectedMenuItem.quantity || 0);
  });

  const openOrderingAppCart = () => {
    const locationSlug = menuItemCart.location?.slug;
    const menuSlug = menuItemCart.selectedMenuItems[0]?.menuItem?.menu?.slug;

    orderingDispatch({ type: 'ordering.seeMyCart' });
    history.push(`/popmenu-order/${locationSlug}/menus/${menuSlug}`);
  };

  const handleCartClick = () => {
    if (!isOrderingAppActive) {
      dispatch(setShowMobileCartModal(true));
      return;
    }

    openOrderingAppCart();
  };

  if (quantity === 0 && isOrderingAppActive) {
    return null;
  }

  return (
    <div className={classes.buttonContainer}>
      <Button
        aria-label="View My Order"
        className={classes.button}
        startIcon={<Icon icon={ShoppingBag} />}
        onClick={() => executeWithProgressBar(handleCartClick)}
        size="large"
        variant="text"
        data-cy="cart_button"
      />
      {quantity !== 0 && (
        <div aria-hidden className={classes.buttonQuantity}>
          {quantity}
        </div>
      )}
    </div>
  );
};

export default CartButtonDisplay;
