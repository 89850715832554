import React from 'react';
import { Helmet, type HelmetProps } from 'react-helmet';

import { useRestaurant } from '../../utils/withRestaurant';

interface MetaProps {
  canonicalUrl?: string
  children?: React.ReactNode
  defaultTitle?: string
  description?: string
  imageHeight?: number
  imageUrl?: string
  imageWidth?: number
  keywords?: string | string[]
  title?: string
  titleTemplate?: string
}

const Meta = (props: MetaProps) => { // eslint-disable-line react/require-default-props
  const {
    children = null,
    defaultTitle = undefined,
    description = null,
    keywords = [],
    title = undefined,
    titleTemplate = undefined,
  } = props;
  let {
    canonicalUrl = null,
    imageHeight = null,
    imageUrl = null,
    imageWidth = null,
  } = props;
  const restaurant = useRestaurant();

  if (!imageUrl && restaurant && restaurant.logoWithPaddingUrl) {
    imageUrl = restaurant.logoWithPaddingUrl;
    imageHeight = 300;
    imageWidth = 300;
  }

  // Canonical URL should be absolute (not relative) and never include any querystring/hash params, to prevent duplicates
  // canonicalUrl prop should include leading slash
  if (canonicalUrl && !canonicalUrl.startsWith('http') && restaurant?.popmenuUrl) {
    canonicalUrl = `${restaurant.popmenuUrl}${String(canonicalUrl.split('?')[0]).split('#')[0]}`;
  }

  // titleTemplate: Template used for page title
  // title: Replaces "%s" in the titleTemplate
  // defaultTitle: Used in place of the titleTemplate when the title is blank
  const helmetProps: HelmetProps = {
    title,
  };
  if (defaultTitle) {
    helmetProps.defaultTitle = defaultTitle;
  }
  if (titleTemplate) {
    helmetProps.titleTemplate = titleTemplate;
  }

  return (
    <Helmet {...helmetProps}>
      {canonicalUrl && (
        <link rel="canonical" href={canonicalUrl} />
      )}
      {description && (
        <meta name="description" content={description} />
      )}
      {description && (
        <meta property="og:description" content={description} />
      )}
      {imageUrl && (
        <meta property="og:image" content={imageUrl} />
      )}
      {imageHeight && (
        <meta property="og:image:height" content={String(imageHeight)} />
      )}
      {imageWidth && (
        <meta property="og:image:width" content={String(imageWidth)} />
      )}
      {keywords && keywords.length && (
        <meta name="keywords" content={typeof keywords === 'string' ? keywords : keywords.join(',')} />
      )}
      {children}
    </Helmet>
  );
};

export default Meta;
