import React, { useMemo } from 'react';
import { Icon } from '@popmenu/common-ui';
import { Help, MinusCircle, PlusCircle, Face, X as XIcon } from '@popmenu/web-icons';
import PropTypes from 'prop-types';
import { InputAdornment as MuiInputAdornment, TextField as MuiTextField, Popover as MuiPopover, Tooltip as MuiTooltip, IconButton } from '@material-ui/core';
import { useFeatureFlags } from '~/utils/featureFlagsContext';
import { classNames, withStyles } from '../../../utils/withStyles';
import textFieldStyles from '../../../assets/jss/shared/textFieldStyles';
import Grid from '../../../shared/Grid';
import withTextFieldDebouncer from './withTextFieldDebouncer';

// Prevent server-side error when importing DOM-dependent libraries
let EmojiPicker = null;

class TextField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      renderEmojiPicker: false,
    };
    this.appendValue = this.appendValue.bind(this);
    this.closeEmojiPopover = this.closeEmojiPopover.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.openEmojiPopover = this.openEmojiPopover.bind(this);
    this.inputRef = props.inputRef || React.createRef();
  }

  componentDidMount() {
    if (this.props.emojiPicker) {
      import(/* webpackChunkName: "emoji-picker-react" */ 'emoji-picker-react')
        .then(({ default: emojiPickerReact }) => {
          EmojiPicker = emojiPickerReact;
          this.setState({ renderEmojiPicker: true });
        });
    }
  }

  openEmojiPopover(event) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  closeEmojiPopover() {
    this.setState({
      anchorEl: null,
    });
  }

  handleEmojiClick(name, data) {
    this.appendValue(data.emoji);
    this.closeEmojiPopover();
  }

  appendValue(value) {
    if (this.inputRef.current) {
      const lastValue = this.inputRef.current.value;
      if (lastValue) {
        this.inputRef.current.value = `${lastValue} ${value}`;
      } else {
        this.inputRef.current.value = value;
      }
      const event = new Event('input', { bubbles: true });
      event.simulated = true;
      if (this.inputRef.current._valueTracker) {
        this.inputRef.current._valueTracker.setValue(lastValue);
      }
      this.inputRef.current.dispatchEvent(event);
    }
  }

  render() {
    const {
      autoComplete,
      characterLimit,
      classes,
      className,
      color,
      disabled,
      emojiPicker,
      endAdornments: endAdornmentsProp,
      field,
      InputProps,
      inputProps,
      isClearable,
      isRequiredTitleLabel,
      label,
      maxValue,
      onClear,
      onIncrementDown,
      onIncrementUp,
      placeholder,
      restaurant,
      rows,
      showSteppers,
      showSteppersOnly,
      startIcon,
      step,
      style,
      title,
      titleTooltip,
      touchDelay,
      variant,
      ...textFieldProps
    } = this.props;
    const endAdornments = [...(endAdornmentsProp || [])];
    const open = Boolean(this.state.anchorEl);

    const dataCySuffix =
      typeof (title || placeholder || '') === 'string' &&
      (title || placeholder || '').toLowerCase() || '';

    // Start Adornment
    let startAdornment = null;
    if (startIcon) {
      startAdornment = (
        <MuiInputAdornment position="start">
          {typeof startIcon === 'string' ? <Icon icon={startIcon} /> : React.createElement(startIcon)}
        </MuiInputAdornment>
      );
    }

    // End Adornment
    let endAdornment = null;
    // Tooltip
    if (titleTooltip) {
      endAdornment = (
        <MuiInputAdornment position="end">
          <MuiTooltip placement="top" title={titleTooltip} enterTouchDelay={touchDelay}>
            <div>
              <Icon icon={Help} />
            </div>
          </MuiTooltip>
        </MuiInputAdornment>
      );
    }

    // Number steppers
    if (textFieldProps.type === 'number' && (showSteppers || showSteppersOnly)) {
      const minusCircleButton = (
        <IconButton
          aria-label={`Remove 1 ${title || placeholder || ''}`}
          color="inherit"
          onClick={onIncrementDown}
          data-cy={`remove_1_${dataCySuffix}`}
        >
          <Icon icon={MinusCircle} />
        </IconButton>
      );

      const plusCircleButton = (
        <IconButton
          disabled={maxValue === parseInt(textFieldProps.value, 10)}
          aria-label={`Add 1 ${title || placeholder || ''}`}
          color="inherit"
          onClick={onIncrementUp}
          data-cy={`add_1_${dataCySuffix}`}
        >
          <Icon icon={PlusCircle} />
        </IconButton>
      );

      if (showSteppersOnly) {
        startAdornment = (
          <MuiInputAdornment position="start">
            {minusCircleButton}
          </MuiInputAdornment>

        );
        endAdornment = (
          <MuiInputAdornment position="end">
            {plusCircleButton}
          </MuiInputAdornment>
        );
      } else {
        endAdornment = (
          <MuiInputAdornment position="end">
            <div className={classes.stepperControls}>
              {minusCircleButton}
              &nbsp;
              {plusCircleButton}
            </div>
          </MuiInputAdornment>
        );
      }
    }

    // Emoji picker
    if (emojiPicker && this.state.renderEmojiPicker) {
      endAdornments.unshift(
        <React.Fragment>
          <Icon
            icon={Face}
            onClick={this.openEmojiPopover}
            style={{ cursor: 'pointer' }}
            data-cy="emoji_picker_button"
          />
          <MuiPopover
            id="simple-popper"
            open={open}
            anchorEl={this.state.anchorEl}
            onClose={this.closeEmojiPopover}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
          >
            <EmojiPicker onEmojiClick={this.handleEmojiClick} />
          </MuiPopover>
        </React.Fragment>,
      );
    }

    if (endAdornments.length > 0) {
      endAdornment = (
        <MuiInputAdornment position="end">
          <Grid container justify="center" alignItems="center" spacing={0} className={this.props.classes.endAdornmentContainer}>
            {endAdornments.map((adornment, i) => (
              <Grid key={i} item>
                {adornment}
              </Grid>
            ))}
          </Grid>
        </MuiInputAdornment>
      );
    }

    // Clear X
    if (isClearable && textFieldProps.value) {
      endAdornment = (
        <MuiInputAdornment position="end">
          <IconButton
            aria-label={'Clear input'}
            color="inherit"
            onClick={onClear}
          >
            <Icon icon={XIcon} />
          </IconButton>
        </MuiInputAdornment>
      );
    }

    // Convert rows to min-height for textarea
    const inputStyle = { ...style };
    if (this.props.multiline && rows > 1) {
      inputStyle.minHeight = `${rows * 16}px`;
    }

    // Ensure only strings are passed to textTitle which
    // is used for the aria-label on the input element
    let textTitle;
    if (title && typeof title === 'string') {
      textTitle = title;
    } else if (label && typeof label === 'string') {
      textTitle = label;
    }

    // Required field suffix
    if (textTitle && isRequiredTitleLabel) {
      textTitle = `${title} (Required)`;
    }

    /* eslint-disable react/jsx-no-duplicate-props */
    // console.log(`TextField.render ${field}`);
    return (
      <MuiTextField
        autoComplete="off"
        className={classNames(
          className,
          classes.textField,
          (showSteppers || showSteppersOnly) && classes.textfieldWithoutUpDownButtons,
          showSteppersOnly && classes.textFieldWithStepperOnly,
        )}
        disabled={disabled}
        inputProps={{
          'aria-label': this.props['aria-label'] || textTitle || placeholder,
          autoComplete,
          className: showSteppersOnly && this.props.classes.centerNumberInField,
          'data-cy': `textfield_${dataCySuffix}`,
          'data-testid': `textfield_${dataCySuffix}`,
          maxLength: characterLimit,
          readOnly: showSteppersOnly,
          step,
          style: inputStyle,
          ...inputProps,
        }}
        InputProps={{
          endAdornment,
          startAdornment,
          ...InputProps,
        }}
        inputRef={this.inputRef}
        label={!showSteppersOnly ? (textTitle || title || label || undefined) : undefined}
        name={field}
        placeholder={placeholder}
        variant={showSteppersOnly ? 'standard' : variant}
        {...textFieldProps}
      />
    );
    /* eslint-enable react/jsx-no-duplicate-props */
  }
}

TextField.defaultProps = {
  'aria-label': null,
  autoComplete: 'off',
  characterLimit: undefined,
  className: null,
  disabled: false,
  emojiPicker: false,
  endAdornments: null,
  field: null,
  InputProps: {},
  inputProps: {},
  isClearable: false,
  isRequiredTitleLabel: false,
  label: undefined,
  maxValue: undefined, // For number steppers
  multiline: false,
  onClear: null,
  onIncrementDown: null,
  onIncrementUp: null,
  placeholder: null,
  restaurant: null,
  rows: 6,
  showSteppers: false,
  showSteppersOnly: false,
  startIcon: null,
  step: null,
  style: {},
  title: null,
  titleTooltip: null,
  touchDelay: null,
  type: 'text',
  value: undefined,
  variant: 'outlined',
};

TextField.propTypes = {
  'aria-label': PropTypes.string,
  autoComplete: PropTypes.string,
  characterLimit: PropTypes.number,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  emojiPicker: PropTypes.bool,
  endAdornments: PropTypes.arrayOf(PropTypes.node),
  field: PropTypes.string,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  isClearable: PropTypes.bool,
  isRequiredTitleLabel: PropTypes.bool,
  label: PropTypes.string,
  maxValue: PropTypes.number,
  multiline: PropTypes.bool,
  onClear: PropTypes.func,
  onIncrementDown: PropTypes.func,
  onIncrementUp: PropTypes.func,
  placeholder: PropTypes.string,
  restaurant: PropTypes.object,
  rows: PropTypes.number,
  showSteppers: PropTypes.bool,
  showSteppersOnly: PropTypes.bool,
  startIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  step: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleTooltip: PropTypes.string,
  touchDelay: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

const TextFieldWrapper = (props) => {
  let progressBarEnabled = false;
  try {
    // useFeatureFlags throws when no restaurant is provided.
    // However initial login page does not have a restaurant but it has TextField.
    // So we need to catch the error and do nothing.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isFeatureActive } = useFeatureFlags();
    progressBarEnabled = isFeatureActive('progress_bar');
  } catch {
    // Do nothing
  }

  const StyledTextField = useMemo(() => withStyles(textFieldStyles)(
    progressBarEnabled ? withTextFieldDebouncer(TextField) : TextField,
  ), [progressBarEnabled]);

  return <StyledTextField {...props} />;
};

export default TextFieldWrapper;
