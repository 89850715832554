import React, { useCallback, useContext, useMemo } from 'react';
import type { IntlShape } from 'react-intl';
import { IntlContext, injectIntl } from 'react-intl';
import type { FormatXMLElementFn } from 'intl-messageformat';

import type { TranslateFunction } from './i18n';
import { t } from './i18n';

export const useIntl = (): TranslateFunction => {
  const intl = useContext(IntlContext);
  return useMemo(() => t(intl), [intl]);
};

interface IntlProps {
  intl: IntlShape;
}

export interface TranslateProps extends IntlProps {
  t: TranslateFunction;
}

export const strong: FormatXMLElementFn<React.ReactNode, React.ReactNode> = chunks => <strong>{chunks}</strong>;

export function withIntl<TProps extends TranslateProps>(Component: React.ComponentType<TProps>) {
  // Explicit type parameters needed because the defaults are weird
  return injectIntl<'intl', Omit<TProps, 't'>>(({ intl, ...props }) => {
    // The cast is necessary because of https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046.
    // The inferred type is props: Omit<React.PropsWithChildren<TProps & IntlProps>, "intl">,
    // which should extend TProps.

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const translateFunction = useCallback(t(intl), [intl]);

    return <Component {...(props as TProps)} intl={intl} t={translateFunction} />;
  });
}
