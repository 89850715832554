import toCamelCase from 'to-camel-case';
import toSnakeCase from 'to-snake-case';

export { toCamelCase, toSnakeCase };

export const containsDigits = (string: string) => /\d/.test(string);

export const formatDistance = (distance: Optional<number>) => {
  if (typeof distance === 'number') {
    const miles = distance.toFixed(1);
    return `${miles} miles`;
  }
  return null;
};

export const formatPhone = (value: Optional<string>) => {
  if (value) {
    const v = String(value).replace(/\D/g, '');
    const patterns = [
      {
        format: '+{1} ({2}) {3}-{4}',
        pattern: /^(1)(\d{3})(\d{3})(\d{4})$/,
      },
      {
        format: '({1}) {2}-{3}',
        pattern: /^(\d{3})(\d{3})(\d{4})$/,
      },
      {
        format: '{1} {2} {3}',
        pattern: /^(\d{3})(\d{4})(\d{4})$/,
      },
    ];
    let formatted: string | null = null;
    patterns.forEach(({ pattern, format }) => {
      if (!formatted) {
        const m = v.match(pattern);
        if (m) {
          let formatted0 = format;
          m.forEach((match, i) => {
            formatted0 = formatted0.replace(`{${i}}`, match);
          });
          formatted = formatted0;
        }
      }
    });
    return formatted ?? v;
  }
  return null;
};

export const incrementNumberInString = (input: string) => {
  const match = input.match(/\d+/);
  if (match) {
    const number = parseInt(match[0], 10) + 1;
    return input.replace(/\d+/, number.toString());
  }
  return input;
};

export const padLeft = (str: string, padStr: string, len: number): string => {
  if (len > str.length) {
    return padLeft(`${padStr}${str}`, padStr, len);
  }
  return str;
};

export const tabName = (name: string) => {
  const splitName = name.trim().split(' ');
  const splitNameLast = splitName[splitName.length - 1];
  if (splitName.length > 1 && splitNameLast) {
    return `${splitName[0]} ${splitNameLast[0]}.`;
  } else {
    return name;
  }
};

export const titleCase = (str: string) => str.toLowerCase().split(' ').map(word => (word.charAt(0).toUpperCase() + word.slice(1))).join(' ');

export const toTitleCase = (str: string) => {
  if (!str) {
    return '';
  }

  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const snakeCaseToTitleCase = (str: string) => (
  str
    .replace(/^[-_]*(.)/, (_, c: string) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c: string) => ` ${c.toUpperCase()}`) // First char after each -/_
);

export const toFirstUpperCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const toUnderscore = (str: string) => str?.split('::').map(part => toSnakeCase(part)).join('/');

export const trimString = (string: string, maxLength: number) => (
  (string.length > maxLength) ? `${string.substring(0, maxLength - 3)}...` : string
);

export const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};
