
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modelsTimeout": {
      "id": "models.timeout",
      "defaultMessage": "Timeout ",
    },
    "modelsCustomFormDollarAmount10": {
      "id": "models.custom_form.dollar_amount.10",
      "defaultMessage": "$10"
    },
    "modelsCustomFormDollarAmount25": {
      "id": "models.custom_form.dollar_amount.25",
      "defaultMessage": "$25"
    },
    "modelsCustomFormDollarAmount50": {
      "id": "models.custom_form.dollar_amount.50",
      "defaultMessage": "$50"
    },
    "modelsCustomFormDollarAmount100": {
      "id": "models.custom_form.dollar_amount.100",
      "defaultMessage": "$100"
    },
    "modelsCustomFormDollarAmount200": {
      "id": "models.custom_form.dollar_amount.200",
      "defaultMessage": "$200"
    },
    "modelsCustomPage::SectionFoodtecCard": {
      "id": "models.custom_page/section.foodtec_card",
      "defaultMessage": "Gift Card Number"
    },
    "modelsCustomPage::SectionFoodtecPin": {
      "id": "models.custom_page/section.foodtec_pin",
      "defaultMessage": "PIN"
    },
    "modelsCustomPage::SectionFoodtecBalance": {
      "id": "models.custom_page/section.foodtec_balance",
      "defaultMessage": "Check Balance"
    },
    "modelsMenuItemCartCurbsidePickup": {
      "id": "models.menu_item_cart.curbside_pickup",
      "defaultMessage": "Curbside Pickup"
    },
    "modelsMenuItemCartDelivery": {
      "id": "models.menu_item_cart.delivery",
      "defaultMessage": "Delivery"
    },
    "modelsMenuItemCartEnterYourPaymentInfo": {
      "id": "models.menu_item_cart.enter_your_payment_info",
      "defaultMessage": "Or enter your payment information below"
    },
    "modelsMenuItemCartInStorePickup": {
      "id": "models.menu_item_cart.in_store_pickup",
      "defaultMessage": "In-Store Pickup"
    },
    "modelsMenuItemCartPaymentRequestButtonContactInfoRequired": {
      "id": "models.menu_item_cart.payment_request_button_contact_info_required",
      "defaultMessage": "Please fill out the contact information before completing your order"
    },
    "modelsMenuItemCartPayOnline": {
      "id": "models.menu_item_cart.pay_online",
      "defaultMessage": "Pay online"
    },
    "modelsMenuItemCartPosOrderFailed": {
      "id": "models.menu_item_cart.pos_order_failed",
      "defaultMessage": "Sorry, the order has failed to submit. Please try again."
    },
    "modelsMenuItemCartPosOfferFailed": {
      "id": "models.menu_item_cart.pos_offer_failed",
      "defaultMessage": "Sorry, the offer can't be applied"
    },
    "modelsMenuItemCartPayOnPickup": {
      "id": "models.menu_item_cart.pay_on_pickup",
      "defaultMessage": "Pay on Pickup"
    },
    "modelsMenuItemCartPickup": {
      "id": "models.menu_item_cart.pickup",
      "defaultMessage": "Pickup"
    },
    "modelsMenuItemCartScheduleForLater": {
      "id": "models.menu_item_cart.schedule_for_later",
      "defaultMessage": "Schedule for Later"
    },
    "modelsMenuItemCartSubmitOrder": {
      "id": "models.menu_item_cart.submit_order",
      "defaultMessage": "Submit Order"
    },
    "modelsMenuItemCartTabLabel": {
      "id": "models.menu_item_cart.tab_label",
      "defaultMessage": "Tab"
    },
    "modelsMenuItemCartTableLabel": {
      "id": "models.menu_item_cart.table_label",
      "defaultMessage": "Table"
    },
    "modelsRestaurant::Location": {
      "id": "models.restaurant/location.self",
      "defaultMessage": "Location"
    },
    "modelsRestaurant::Location::NoLocationSelected": {
      "id": "models.restaurant/location.no_location_selected",
      "defaultMessage": "No Preferred Location"
    },
    "modelsRestaurant::Location::OpeningRangeDayEveryday": {
      "id": "models.restaurant/location/opening_range.day_everyday",
      "defaultMessage": "Every Day"
    },
    "modelsRestaurant::Location::OpeningRangeDaySunday": {
      "id": "models.restaurant/location/opening_range.day_sunday",
      "defaultMessage": "Sunday"
    },
    "modelsRestaurant::Location::OpeningRangeDayMonday": {
      "id": "models.restaurant/location/opening_range.day_monday",
      "defaultMessage": "Monday"
    },
    "modelsRestaurant::Location::OpeningRangeDayTuesday": {
      "id": "models.restaurant/location/opening_range.day_tuesday",
      "defaultMessage": "Tuesday"
    },
    "modelsRestaurant::Location::OpeningRangeDayWednesday": {
      "id": "models.restaurant/location/opening_range.day_wednesday",
      "defaultMessage": "Wednesday"
    },
    "modelsRestaurant::Location::OpeningRangeDayThursday": {
      "id": "models.restaurant/location/opening_range.day_thursday",
      "defaultMessage": "Thursday"
    },
    "modelsRestaurant::Location::OpeningRangeDayFriday": {
      "id": "models.restaurant/location/opening_range.day_friday",
      "defaultMessage": "Friday"
    },
    "modelsRestaurant::Location::OpeningRangeDaySaturday": {
      "id": "models.restaurant/location/opening_range.day_saturday",
      "defaultMessage": "Saturday"
    },
    "modelsRestaurant::LocationCountriesAe": {
      "id": "models.restaurant/location.countries.AE",
      "defaultMessage": "United Arab Emirates"
    },
    "modelsRestaurant::LocationCountriesAu": {
      "id": "models.restaurant/location.countries.AU",
      "defaultMessage": "Australia"
    },
    "modelsRestaurant::LocationCountriesBs": {
      "id": "models.restaurant/location.countries.BS",
      "defaultMessage": "The Bahamas"
    },
    "modelsRestaurant::LocationCountriesCa": {
      "id": "models.restaurant/location.countries.CA",
      "defaultMessage": "Canada"
    },
    "modelsRestaurant::LocationCountriesDo": {
      "id": "models.restaurant/location.countries.DO",
      "defaultMessage": "Dominican Republic"
    },
    "modelsRestaurant::LocationCountriesFr": {
      "id": "models.restaurant/location.countries.FR",
      "defaultMessage": "France"
    },
    "modelsRestaurant::LocationCountriesGb": {
      "id": "models.restaurant/location.countries.GB",
      "defaultMessage": "United Kingdom"
    },
    "modelsRestaurant::LocationCountriesGr": {
      "id": "models.restaurant/location.countries.GR",
      "defaultMessage": "Greece"
    },
    "modelsRestaurant::LocationCountriesIn": {
      "id": "models.restaurant/location.countries.IN",
      "defaultMessage": "India"
    },
    "modelsRestaurant::LocationCountriesJe": {
      "id": "models.restaurant/location.countries.JE",
      "defaultMessage": "Jersey"
    },
    "modelsRestaurant::LocationCountriesKr": {
      "id": "models.restaurant/location.countries.KR",
      "defaultMessage": "South Korea"
    },
    "modelsRestaurant::LocationCountriesMx": {
      "id": "models.restaurant/location.countries.MX",
      "defaultMessage": "Mexico"
    },
    "modelsRestaurant::LocationCountriesHn": {
      "id": "models.restaurant/location.countries.HN",
      "defaultMessage": "Honduras"
    },
    "modelsRestaurant::LocationCountriesHR": {
      "id": "models.restaurant/location.countries.HR",
      "defaultMessage": "Croatia"
    },
    "modelsRestaurant::LocationCountriesVg": {
      "id": "models.restaurant/location.countries.VG",
      "defaultMessage": "British Virgin Islands"
    },
    "modelsRestaurant::LocationCountriesPr": {
      "id": "models.restaurant/location.countries.PR",
      "defaultMessage": "Puerto Rico"
    },
    "modelsRestaurant::LocationCountriesNg": {
      "id": "models.restaurant/location.countries.NG",
      "defaultMessage": "Nigeria"
    },
    "modelsRestaurant::LocationCountriesQa": {
      "id": "models.restaurant/location.countries.QA",
      "defaultMessage": "Qatar"
    },
    "modelsRestaurant::LocationCountriesUs": {
      "id": "models.restaurant/location.countries.US",
      "defaultMessage": "United States"
    },
    "modelsRestaurant::LocationCountriesGu": {
      "id": "models.restaurant/location.countries.GU",
      "defaultMessage": "Guam"
    },
    "modelsRestaurant::LocationCountriesJp": {
      "id": "models.restaurant/location.countries.JP",
      "defaultMessage": "Japan"
    },
    "modelsRestaurant::LocationCountriesAr": {
      "id": "models.restaurant/location.countries.AR",
      "defaultMessage": "Argentina"
    },
    "modelsRestaurant::LocationCountriesIe": {
      "id": "models.restaurant/location.countries.IE",
      "defaultMessage": "Ireland"
    },
    "modelsRestaurant::LocationCountriesVI": {
      "id": "models.restaurant/location.countries.VI",
      "defaultMessage": "U.S. Virgin Islands"
    },
    "modelsFollowerFavoriteLocationName": {
      "id": "models.follower.favorite_location.name",
      "defaultMessage": "Favorite Location"
    },
    "modelsFollowerFavoriteLocationNameRequired": {
      "id": "models.follower.favorite_location.name.required",
      "defaultMessage": "Favorite Location (required)"
    },
    "modelsFollowerFavoriteLocationWelcome": {
      "id": "models.follower.favorite_location.welcome",
      "defaultMessage": "You're logged into {restaurant}. Please tell us your favorite location."
    },
    "modelsUserFullName": {
      "id": "models.user.full_name",
      "defaultMessage": "Full Name"
    },
    "modelsUserBirthdayTitleTooltip": {
      "id": "models.user.birthday_title_tooltip",
      "defaultMessage": "Enter your birthday to receive special birthday offers and treats!"
    },
    "modelsUserBirthDay": {
      "id": "models.user.birth_day",
      "defaultMessage": "Birthday Day"
    },
    "modelsUserDay": {
      "id": "models.user.day",
      "defaultMessage": "Day"
    },
    "modelsUserBirthMonth": {
      "id": "models.user.birth_month",
      "defaultMessage": "Birthday Month"
    },
    "modelsUserMonth": {
      "id": "models.user.month",
      "defaultMessage": "Month"
    },
    "modelsUserYear": {
      "id": "models.user.year",
      "defaultMessage": "Year"
    },
    "modelsUserBirthday": {
      "id": "models.user.birthday",
      "defaultMessage": "Birthday"
    },
    "modelsUserBirthDays1": {
      "id": "models.user.birth_days.1",
      "defaultMessage": "1"
    },
    "modelsUserBirthDays2": {
      "id": "models.user.birth_days.2",
      "defaultMessage": "2"
    },
    "modelsUserBirthDays3": {
      "id": "models.user.birth_days.3",
      "defaultMessage": "3"
    },
    "modelsUserBirthDays4": {
      "id": "models.user.birth_days.4",
      "defaultMessage": "4"
    },
    "modelsUserBirthDays5": {
      "id": "models.user.birth_days.5",
      "defaultMessage": "5"
    },
    "modelsUserBirthDays6": {
      "id": "models.user.birth_days.6",
      "defaultMessage": "6"
    },
    "modelsUserBirthDays7": {
      "id": "models.user.birth_days.7",
      "defaultMessage": "7"
    },
    "modelsUserBirthDays8": {
      "id": "models.user.birth_days.8",
      "defaultMessage": "8"
    },
    "modelsUserBirthDays9": {
      "id": "models.user.birth_days.9",
      "defaultMessage": "9"
    },
    "modelsUserBirthDays10": {
      "id": "models.user.birth_days.10",
      "defaultMessage": "10"
    },
    "modelsUserBirthDays11": {
      "id": "models.user.birth_days.11",
      "defaultMessage": "11"
    },
    "modelsUserBirthDays12": {
      "id": "models.user.birth_days.12",
      "defaultMessage": "12"
    },
    "modelsUserBirthDays13": {
      "id": "models.user.birth_days.13",
      "defaultMessage": "13"
    },
    "modelsUserBirthDays14": {
      "id": "models.user.birth_days.14",
      "defaultMessage": "14"
    },
    "modelsUserBirthDays15": {
      "id": "models.user.birth_days.15",
      "defaultMessage": "15"
    },
    "modelsUserBirthDays16": {
      "id": "models.user.birth_days.16",
      "defaultMessage": "16"
    },
    "modelsUserBirthDays17": {
      "id": "models.user.birth_days.17",
      "defaultMessage": "17"
    },
    "modelsUserBirthDays18": {
      "id": "models.user.birth_days.18",
      "defaultMessage": "18"
    },
    "modelsUserBirthDays19": {
      "id": "models.user.birth_days.19",
      "defaultMessage": "19"
    },
    "modelsUserBirthDays20": {
      "id": "models.user.birth_days.20",
      "defaultMessage": "20"
    },
    "modelsUserBirthDays21": {
      "id": "models.user.birth_days.21",
      "defaultMessage": "21"
    },
    "modelsUserBirthDays22": {
      "id": "models.user.birth_days.22",
      "defaultMessage": "22"
    },
    "modelsUserBirthDays23": {
      "id": "models.user.birth_days.23",
      "defaultMessage": "23"
    },
    "modelsUserBirthDays24": {
      "id": "models.user.birth_days.24",
      "defaultMessage": "24"
    },
    "modelsUserBirthDays25": {
      "id": "models.user.birth_days.25",
      "defaultMessage": "25"
    },
    "modelsUserBirthDays26": {
      "id": "models.user.birth_days.26",
      "defaultMessage": "26"
    },
    "modelsUserBirthDays27": {
      "id": "models.user.birth_days.27",
      "defaultMessage": "27"
    },
    "modelsUserBirthDays28": {
      "id": "models.user.birth_days.28",
      "defaultMessage": "28"
    },
    "modelsUserBirthDays29": {
      "id": "models.user.birth_days.29",
      "defaultMessage": "29"
    },
    "modelsUserBirthDays30": {
      "id": "models.user.birth_days.30",
      "defaultMessage": "30"
    },
    "modelsUserBirthDays31": {
      "id": "models.user.birth_days.31",
      "defaultMessage": "31"
    },
    "modelsUserBirthMonthsJanuary": {
      "id": "models.user.birth_months.January",
      "defaultMessage": "January"
    },
    "modelsUserBirthMonthsFebruary": {
      "id": "models.user.birth_months.February",
      "defaultMessage": "February"
    },
    "modelsUserBirthMonthsMarch": {
      "id": "models.user.birth_months.March",
      "defaultMessage": "March"
    },
    "modelsUserBirthMonthsApril": {
      "id": "models.user.birth_months.April",
      "defaultMessage": "April"
    },
    "modelsUserBirthMonthsMay": {
      "id": "models.user.birth_months.May",
      "defaultMessage": "May"
    },
    "modelsUserBirthMonthsJune": {
      "id": "models.user.birth_months.June",
      "defaultMessage": "June"
    },
    "modelsUserBirthMonthsJuly": {
      "id": "models.user.birth_months.July",
      "defaultMessage": "July"
    },
    "modelsUserBirthMonthsAugust": {
      "id": "models.user.birth_months.August",
      "defaultMessage": "August"
    },
    "modelsUserBirthMonthsSeptember": {
      "id": "models.user.birth_months.September",
      "defaultMessage": "September"
    },
    "modelsUserBirthMonthsOctober": {
      "id": "models.user.birth_months.October",
      "defaultMessage": "October"
    },
    "modelsUserBirthMonthsNovember": {
      "id": "models.user.birth_months.November",
      "defaultMessage": "November"
    },
    "modelsUserBirthMonthsDecember": {
      "id": "models.user.birth_months.December",
      "defaultMessage": "December"
    },
    "modelsUserCurrentPassword": {
      "id": "models.user.current_password",
      "defaultMessage": "Current password"
    },
    "modelsUserPassword": {
      "id": "models.user.password",
      "defaultMessage": "New password"
    },
    "modelsUserPasswordConfirmation": {
      "id": "models.user.password_confirmation",
      "defaultMessage": "New password confirmation"
    },
    "modelsUserEmail": {
      "id": "models.user.email",
      "defaultMessage": "Email"
    },
    "modelsUserFirstName": {
      "id": "models.user.first_name",
      "defaultMessage": "First name"
    },
    "modelsUserLastName": {
      "id": "models.user.last_name",
      "defaultMessage": "Last name"
    },
    "modelsUserRole": {
      "id": "models.user.role",
      "defaultMessage": "Role"
    },
    "modelsUserPhone": {
      "id": "models.user.phone",
      "defaultMessage": "Phone"
    },
    "modelsUserMobile": {
      "id": "models.user.mobile",
      "defaultMessage": "Mobile"
    },
    "modelsUserOptInInformationalTexts": {
      "id": "models.user.optInInformationalTexts",
      "defaultMessage": "Opt-in of Informational Text Messaging"
    },
    "modelsUserOptInPromotionalTexts": {
      "id": "models.user.optInPromotionalTexts",
      "defaultMessage": "Opt-in of Promotional Text Messaging"
    },
    "modelsUserTextNotifications": {
      "id": "models.user.textNotifications",
      "defaultMessage": "Text Notifications"
    },
    "modelsUserOptInInformationalTextsDescription": {
      "id": "models.user.optInTooltip.informationalTextsDescription",
      "defaultMessage": "Informational text messaging: By providing your mobile phone number, you are requesting to receive transactional text messages relating to your Popmenu account to the telephone number provided by you. You can opt out at any time by replying with STOP. Message and data rates may apply."
    },
    "modelsUserOptInPromotionalTextsDescription": {
      "id": "models.user.optInTooltip.promotionalTextsDescription",
      "defaultMessage": "Promotional text messaging: By checking this box, you are requesting to receive marketing text messages (maximum one per week) from Popmenu and authorizing Popmenu to send text messages to the telephone number provided by you using an automated system for the selection of telephone numbers. You can unsubscribe at any time by replying with STOP. You are not required to agree to enter into this agreement as a condition of purchasing any property, goods, or services. Message and data rates may apply."
    },
    "modelsUserName": {
      "id": "models.user.name",
      "defaultMessage": "Name"
    },
    "modelsUserWeddingMonth": {
      "id": "models.user.wedding_month",
      "defaultMessage": "Wedding Anniversary Month"
    },
    "modelsUserWeddingDay": {
      "id": "models.user.wedding_day",
      "defaultMessage": "Wedding Day"
    },
    "modelsBirthday": {
      "id": "models.user.birthday",
      "defaultMessage": "Birthday"
    },
    "modelsPasswordTooltip": {
      "id": "models.user.password_tooltip",
      "defaultMessage": "Creating a password allows you to log into your account easily. You can use this password for multiple Popmenu restaurant partners."
    },
    "modelsPhoneTooltip": {
      "id": "models.user.phone_tooltip",
      "defaultMessage": "Verify your account faster next time!"
    },
    "modelsBirthdayTooltip": {
      "id": "models.user.birth_day_tooltip",
      "defaultMessage": "If we know your birthday we can send you birthday wishes, discounts and recommendations for cocktails."
    },
    "modelsUpdateButton": {
      "id": "models.user.update",
      "defaultMessage": "Update"
    },
    "modelsPhoneHelperText": {
      "id": "models.user.phone_helper_text",
      "defaultMessage": "000-000-0000 or (000) 000-0000"
    },
    "modelsUpdatePassword": {
      "id": "models.user.update_password",
      "defaultMessage": "Password"
    },
    "modelsPasswordHelperText": {
      "id": "models.user.password_helper_text",
      "defaultMessage": "Passwords must be 8+ characters, with both upper and lower cases, a number, and special character. May not include 'Popmenu'"
    },
  }
);
export default defaultMessages;
