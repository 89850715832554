import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { visualRegressionsMode } from '../../../utils/visualRegressionsMode';

const Modal = ({ LoadableComponent, path, visregNavOnly, ...props }) => {
  let disableModalForVisreg = false;
  if (process.env.IS_INSTRUMENTED_BUILD && visualRegressionsMode) {
    if (!visualRegressionsMode.startsWith('nav_header_footer') &&
        !visualRegressionsMode.startsWith('full_page') &&
        visregNavOnly) {
      disableModalForVisreg = true;
    }
  }

  let showModal = useSelector(state => get(state, path));
  showModal = typeof showModal === 'object' ? showModal.showModal : showModal;

  if (disableModalForVisreg) return null;
  if (!showModal) return null;
  return <LoadableComponent {...props} />;
};

export default Modal;
