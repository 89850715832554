import React from 'react';
import CustomImg from '../../../shared/CustomImg';
import CustomLink from '../../shared/CustomLink';
import { AH } from '../../shared/AccessibleHeading';
import { classNames } from '../../../utils/withStyles';

interface SectionType {
  columnPhotoUrl: string;
  columnPhotoAlt: string;
  columnPhotoOverlayText: string;
  columnPhotoOverlayTextAlt: string | undefined | null;
  columnLinkUrl: string;
  columnSecondLinkUrl: string;
  disableAjaxLink: boolean;
}

export const renderSectionWithPhotoUrl = (
  section: SectionType,
  classes: Record<string, string>,
  multiColumnImgObjectFit: string,
) => {
  const image = (
    <CustomImg
      className={classes.img}
      style={{ objectFit: multiColumnImgObjectFit as React.CSSProperties['objectFit'] }}
      src={section.columnPhotoUrl}
      alt={section.columnPhotoAlt}
    />
  );

  if (section.columnPhotoOverlayText) {
    const overlayText = (
      <AH
        typography
        align="center"
        className={classNames(classes.imgOverlayText, 'pm-img-overlay-text')}
        variant="h4"
      >
        {section.columnPhotoOverlayText}
      </AH>
    );

    if (section.columnLinkUrl && !section.columnSecondLinkUrl) {
      return (
        <div>
          <CustomLink
            aria-label={section.columnPhotoOverlayTextAlt || section.columnPhotoOverlayText}
            className={classes.imgOverlay}
            disableAjaxLoad={section.disableAjaxLink}
            url={section.columnLinkUrl}
          >
            {overlayText}
          </CustomLink>
          {image}
        </div>
      );
    }

    return (
      <div>
        <span className={classes.imgOverlay}>
          {overlayText}
        </span>
        {image}
      </div>
    );
  }

  if (section.columnLinkUrl) {
    return (
      <div>
        <CustomLink
          aria-label={`${section.columnPhotoAlt} link`}
          disableAjaxLoad={section.disableAjaxLink}
          url={section.columnLinkUrl}
        >
          {image}
        </CustomLink>
      </div>
    );
  }
  return image;
};
