// This file contains a set of workarounds for a bug in react-virtuoso:
// Sometimes virtuoso scrolls to a wrong position and we need to programmatically retry the scrolling.
// This activity should be interrupted by user scrolls.

import { isSSR } from '~/utils/dom';

const RETRIES_COUNT = 8;
const RETRIES_INTERVAL_MS = 200;
// Virtuoso doesn't make more than 4 scroll events.
// Scrolls coming from users almost always spam more than 4 scroll events in quick succession.
// By counting scroll events since onStartProgrammaticScroll we can distinguish user scrolls from programmatic ones.
const MAX_PROGRAMMATIC_SCROLLS_COUNT = 4;

let scrollsCountSinceLastProgrammaticScroll = 0;
let retriesSetTimeoutId;
const userScrollListeners = new Set();

export const addUserScrollListener = (listener) => {
  userScrollListeners.add(listener);
};

export const removeUserScrollListener = (listener) => {
  userScrollListeners.delete(listener);
};

if (!isSSR) {
  window.addEventListener('scroll', () => {
    scrollsCountSinceLastProgrammaticScroll += 1;
    if (scrollsCountSinceLastProgrammaticScroll > MAX_PROGRAMMATIC_SCROLLS_COUNT) {
      // Manual scroll detected. Interrupting scrolling retries.
      clearTimeout(retriesSetTimeoutId);
      userScrollListeners.forEach(listener => listener());
    }
  }, { passive: true });
}

function onStartProgrammaticScroll() {
  clearTimeout(retriesSetTimeoutId);
  scrollsCountSinceLastProgrammaticScroll = 0;
}

export const repeatUntilUserScroll = (callback) => {
  onStartProgrammaticScroll();

  let retryCount = 0;

  const retry = () => {
    if (retryCount > 0 && scrollsCountSinceLastProgrammaticScroll === 0) {
      // Previous |retry| call didn't cause scroll events.
      // This means virtuoso successfully scrolled to the right section.
      return;
    }

    onStartProgrammaticScroll();
    callback();
    retryCount += 1;

    if (retryCount < RETRIES_COUNT) {
      retriesSetTimeoutId = setTimeout(retry, RETRIES_INTERVAL_MS);
    }
  };

  // Postponing virtuoso scrolls to a separate task from the curent React rendering iteration
  // makse it less flaky.
  setTimeout(() => {
    retry();
  }, 0);
};
