import type { CSSProperties } from 'react';
import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { withTheme, type WithThemeProps } from '../utils/withTheme';
import { classNames } from '../utils/withStyles';

const LOADING_TIMEOUT_MS = 100;

type LoadingProps = {
  className?: string;
  size?: 'fullscreen' | 'fullscreen-align-top' | 'sm' | 'md' | 'lg' | 'xl';
  useVisibilityTimeout?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
} & WithThemeProps;

class Loading extends React.PureComponent<LoadingProps, { visible: boolean }> {
  private visibilityTimeout: NodeJS.Timeout | undefined;

  static readonly defaultProps: Partial<LoadingProps> = {
    className: undefined,
    containerRef: undefined,
    size: 'md',
    useVisibilityTimeout: true,
  };

  constructor(props: LoadingProps) {
    super(props);
    this.state = {
      visible: !props.useVisibilityTimeout,
    };
    this.visibilityTimeout = undefined;
  }

  // Prevent displaying visible loading spinner for a short time,
  // so that some queries that normally load in less than ~100 ms feel instantaneous
  componentDidMount() {
    this.visibilityTimeout = setTimeout(() => {
      this.setState({ visible: true });
    }, LOADING_TIMEOUT_MS);
  }

  componentWillUnmount() {
    clearTimeout(this.visibilityTimeout);
  }

  render() {
    const wrapStyle: CSSProperties = {
      margin: '16px auto',
      textAlign: 'center',
    };
    const progressStyle: CSSProperties = {
      color: this.props.theme?.primaryColor ?? undefined,
      visibility: this.state.visible ? 'visible' : 'hidden',
    };
    let size;
    switch (this.props.size) {
      case 'fullscreen':
        size = 100;
        wrapStyle.alignItems = 'center';
        wrapStyle.display = 'flex';
        wrapStyle.height = '100vh';
        wrapStyle.justifyContent = 'center';
        wrapStyle.margin = 0;
        wrapStyle.textAlign = 'center';
        wrapStyle.width = '100%';
        break;
      case 'sm':
        return <CircularProgress className={this.props.className ?? undefined} size="sm" style={progressStyle} />;
      case 'fullscreen-align-top':
        size = 100;
        progressStyle.marginBottom = '100vh';
        break;
      case 'lg':
        size = 100;
        break;
      case 'xl':
        size = 150;
        break;
      case 'md':
      default:
        size = 50;
        break;
    }
    return (
      <div
        ref={this.props.containerRef}
        className="pm-loading-wrap"
        style={wrapStyle}
      >
        <CircularProgress aria-label="Loading Icon" className={classNames('pm-loading', this.props.className)} size={size} style={progressStyle} />
      </div>
    );
  }
}

export default withTheme(Loading);
