import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';

import { getAppStaticProps } from '~/utils/appStaticProps';
import { withWindowContext } from './WindowProvider';
import { withWindowSizeContext } from './WindowSizeProvider';
import { themeShape, withTheme } from '../utils/withTheme';
import { offset } from '../utils/dom';
import { visualRegressionsMode } from '../utils/visualRegressionsMode';
import { useFeatureFlags } from '../utils/featureFlagsContext';
import { useScreenReaderMode } from '../utils/screenReaderMode/useScreenReaderMode';

const TRANSITION_TIMEOUT_MS = 500;
const FADE_TRANSITION_TIMEOUT_MS = 1000;

const Transition = (props) => {
  const { addResizeListener, addScrollListener, children, removeResizeListener, removeScrollListener, theme, transitionTiming, transitionType, waitForScrolled, windowHeight, windowScrolled, isHeaderEnabled, hasMenuSection, sectionIndex, visibleSectionsCount } = props;
  const { screenReaderMode } = useScreenReaderMode();
  const sectionRef = useRef();
  const [scrolledTo, setScrolledTo] = useState(false);
  const { isFeatureActive } = useFeatureFlags();
  const { isMobileUserAgent } = getAppStaticProps();

  const timing = (isFeatureActive('transition_timing') && (transitionTiming || theme.defaultTransitionTiming)) || TRANSITION_TIMEOUT_MS;
  const fadeTiming = (isFeatureActive('transition_timing') && (transitionTiming || theme.defaultTransitionTiming)) || FADE_TRANSITION_TIMEOUT_MS;

  // Slide transition causes overflow on mobile
  let transition = transitionType || theme.defaultTransitionType || 'tt_none';
  if (isMobileUserAgent && (transition === 'tt_slide' || (sectionIndex < visibleSectionsCount))) {
    transition = 'tt_none';
  }

  //  A perf tweak. To get better LH scores the content that is visible in the initial viewport shouldn't be hidden
  // under Transition. Otherwise the page remains blank until the hydration which contributes to LH regressions and poor UX.
  const contentIsInitiallyVisible = !waitForScrolled && !isHeaderEnabled;
  // This have negative effect to desktop and tablets because they have bigger screens which with the current menu section skeleton
  // would cause strong cumulative shifts while loading on some pages.
  if (isMobileUserAgent && contentIsInitiallyVisible && hasMenuSection) {
    transition = 'tt_none';
  }

  if (process.env.IS_INSTRUMENTED_BUILD && visualRegressionsMode) {
    transition = 'tt_none';
  }

  useEffect(() => {
    const handleScroll = () => {
      if (!scrolledTo && sectionRef.current) {
        if (!waitForScrolled || (windowScrolled && (offset(sectionRef.current).top <= windowHeight + 20))) {
          removeResizeListener(handleScroll);
          removeScrollListener(handleScroll);
          setScrolledTo(true);
        }
      }
    };
    if (transition !== 'tt_none') {
      addResizeListener(handleScroll);
      addScrollListener(handleScroll);
      handleScroll();
    }
    return function cleanup() {
      removeResizeListener(handleScroll);
      removeScrollListener(handleScroll);
    };
  }, [addResizeListener, addScrollListener, sectionRef, removeResizeListener, removeScrollListener, scrolledTo, transition, waitForScrolled, windowHeight, windowScrolled]);

  const inProp = scrolledTo || screenReaderMode;

  switch (transition) {
    case 'tt_fade':
      return (
        <div ref={sectionRef}>
          <Fade in={inProp} timeout={fadeTiming}>
            <div>
              {children}
            </div>
          </Fade>
        </div>
      );
    case 'tt_grow':
      return (
        <div ref={sectionRef}>
          <Grow in={inProp} timeout={timing}>
            <div>
              {children}
            </div>
          </Grow>
        </div>
      );
    case 'tt_slide':
      return (
        <div ref={sectionRef}>
          <Slide direction="left" in={inProp} timeout={timing}>
            <div>
              {children}
            </div>
          </Slide>
        </div>
      );
    default:
      return (
        <div ref={sectionRef}>
          {children}
        </div>
      );
  }
};

Transition.defaultProps = {
  hasMenuSection: false,
  isHeaderEnabled: true,
  sectionIndex: 0,
  transitionTiming: 0,
  transitionType: null,
  visibleSectionsCount: 0,
  waitForScrolled: true,
};

Transition.propTypes = {
  addResizeListener: PropTypes.func.isRequired,
  addScrollListener: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  hasMenuSection: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool,
  removeResizeListener: PropTypes.func.isRequired,
  removeScrollListener: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number,
  theme: themeShape.isRequired,
  transitionTiming: PropTypes.number,
  transitionType: PropTypes.oneOf(['tt_fade', 'tt_grow', 'tt_none', 'tt_slide']),
  visibleSectionsCount: PropTypes.number,
  waitForScrolled: PropTypes.bool,
  windowHeight: PropTypes.number.isRequired,
  windowScrolled: PropTypes.bool.isRequired,
};

export default compose(
  withWindowContext,
  withWindowSizeContext,
  withTheme,
  mapProps(({ window, windowSize, ...props }) => ({
    ...props,
    addResizeListener: windowSize.addResizeListener,
    addScrollListener: window.addScrollListener,
    removeResizeListener: windowSize.removeResizeListener,
    removeScrollListener: window.removeScrollListener,
    windowHeight: windowSize.innerHeight,
    windowScrolled: window.scrolled,
  })),
)(Transition);
