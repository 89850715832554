import type { Store as StoreLib } from 'redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunkMiddleware, { type ThunkDispatch } from 'redux-thunk';

// @ts-expect-error Internal file is not TypeScript
import AdvisorBoardReducer from '~/shared/AdvisorBoardReducer';
// @ts-expect-error Internal file is not TypeScript
import AnnouncementsReducer from '~/shared/AnnouncementsReducer';
// @ts-expect-error Internal file is not TypeScript
import AnsweringSignUpReducer from '~/shared/AnsweringSignUpReducer';
// @ts-expect-error Internal file is not TypeScript
import BuilderReducer from '~/shared/BuilderReducer';
// @ts-expect-error Internal file is not TypeScript
import ConsumerReducer from '~/shared/ConsumerReducer';
import DishesReducer from '~/shared/DishesReducer';
// @ts-expect-error Internal file is not TypeScript
import FeaturedSectionReducer from '~/shared/FeaturedSectionReducer';
// @ts-expect-error Internal file is not TypeScript
import UpgradeRequestReducer from '~/shared/UpgradeRequest/UpgradeRequestReducer';
import type { MenuItemCartAction } from '~/shared/MenuItemCartActions';
import MenuItemCartReducer, { type MenuItemCartState } from '~/shared/MenuItemCartReducer';
import ModalsReducer, { type ModalsState } from '~/shared/ModalsReducer';
// @ts-expect-error Internal file is not TypeScript
import NewsNotificationsReducer from '~/shared/NewsNotificationReducer';
// @ts-expect-error Internal file is not TypeScript
import OffersReducer from '~/shared/OffersReducer';
// @ts-expect-error Internal file is not TypeScript
import OrderingReducer from '~/shared/OrderingReducer';
// @ts-expect-error Internal file is not TypeScript
import SnackbarsReducer from '~/shared/SnackbarsReducer';
import StripeReducer, { type StripeState } from '~/shared/StripeReducer';
// @ts-expect-error Internal file is not TypeScript
import TextMessageGroupsReducer from '~/shared/TextMessageGroupsReducer';
// @ts-expect-error Internal file is not TypeScript
import WebBuilderReducer from '~/shared/WebBuilderReducer';
import type { SnackbarAction } from '~/shared/SnackbarActions';
import type { DishesAction } from '~/shared/DishActions';
import type { DishesState } from '~/shared/DishesState';
import type { StripeAction } from '~/shared/StripeActions';
import type { OrderManagementEvent, OrderManagementState } from '~/shared/AdminOrderManagementReducer';
import { adminOrderManagementReducer } from '~/shared/AdminOrderManagementReducer';
import type { RootState as OnlineOrderingState } from '~/orderingApp/types/state';
import { onlineOrderingReducer } from '~/orderingApp/state';
import { type AdminOrderingEventManagementEvent, adminOrderingEventManagementReducer, type AdminOrderingEventManagementState } from '~/shared/AdminOrderingEventManagementReducer';
import type { AdminRestaurantFeedbackEvent } from '~/shared/AdminRestaurantFeedbackReducer';
import { adminRestaurantFeedbackReducer, type AdminRestaurantFeedbackState } from '~/shared/AdminRestaurantFeedbackReducer';
import { replyModalReducer, type ReplyModalEvent, type ReplyModalState } from '~/admin/menus/unified_reviews/modals/reducer';
import type { MenuManagementAction } from '~/shared/MenuManagementReducer';
import { menuManagementReducer } from '~/shared/MenuManagementReducer';
import type { NotYetTyped } from './types';

// The real definition will be imported from app/javascript/shared/ConsumerReducer after it's converted to TypeScript.
// This is only what's needed for now.
interface ConsumerState {
  orderingEventId: Optional<number>;
}

// Add types as the files get converted to TypeScript
export interface SharedRootState {
  features: NotYetTyped;
  offers: NotYetTyped;
  modals: ModalsState;
  stripe: StripeState;
}

export interface AdminRootState extends SharedRootState {
  advisorBoard: NotYetTyped;
  answeringSignup: NotYetTyped;
  builder: NotYetTyped;
  newsNotification: NotYetTyped;
  ordering: NotYetTyped;
  orderingEventManagement: AdminOrderingEventManagementState;
  orderManagement: OrderManagementState;
  replyModal: ReplyModalState;
  restaurantFeedback: AdminRestaurantFeedbackState;
  snackbars: NotYetTyped;
  textMessageGroups: NotYetTyped;
  webBuilder: NotYetTyped;
  menuManagement: NonNullable<Parameters<typeof menuManagementReducer>[0]>;
}

export interface ConsumerRootState extends SharedRootState, OnlineOrderingState {
  announcements: NotYetTyped;
  consumer: ConsumerState;
  dishes: DishesState;
  featuredSection: NotYetTyped;
  menuItemCart: MenuItemCartState;
}

export interface RootState extends ConsumerRootState, AdminRootState { }

// Add union with other action types as the files get converted to TypeScript.
export type Action = ConsumerAction | AdminAction

export type SharedAction = StripeAction

export type ConsumerAction = SharedAction | MenuItemCartAction | DishesAction

export type AdminAction = SharedAction | SnackbarAction | OrderManagementEvent | AdminOrderingEventManagementEvent | AdminRestaurantFeedbackEvent | ReplyModalEvent | MenuManagementAction

export type Dispatch<TAction extends Action> = ThunkDispatch<RootState, undefined, TAction>;

export type Store = StoreLib<RootState, Action> & {
  dispatch: Dispatch<Action>;
}

export type AdminStore = StoreLib<AdminRootState, Action> & {
  dispatch: Dispatch<AdminAction>;
}

export type ConsumerStore = StoreLib<ConsumerRootState, Action> & {
  dispatch: Dispatch<ConsumerAction>;
}

const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composedEnhancers = composeWithDevTools(middlewareEnhancer);

// After all reducers are converted, we can try this approach
// https://github.com/reduxjs/redux-thunk/blob/v2.4.2/typescript_test/typescript.ts to avoid casts.
// And infer RootStore/Dispatch from this as in
// https://react-redux.js.org/using-react-redux/usage-with-typescript#define-root-state-and-dispatch-types
export default function initStore(): Store {
  // Merge reducers into root reducer
  const rootReducer = combineReducers({
    advisorBoard: AdvisorBoardReducer,
    announcements: AnnouncementsReducer,
    answeringSignup: AnsweringSignUpReducer,
    builder: BuilderReducer,
    consumer: ConsumerReducer,
    dishes: DishesReducer,
    featuredSection: FeaturedSectionReducer,
    features: UpgradeRequestReducer,
    menuItemCart: MenuItemCartReducer,
    menuManagement: menuManagementReducer,
    modals: ModalsReducer,
    newsNotification: NewsNotificationsReducer,
    offers: OffersReducer,
    onlineOrdering: onlineOrderingReducer,
    ordering: OrderingReducer,
    orderingEventManagement: adminOrderingEventManagementReducer,
    orderManagement: adminOrderManagementReducer,
    replyModal: replyModalReducer,
    restaurantFeedback: adminRestaurantFeedbackReducer,
    snackbars: SnackbarsReducer,
    stripe: StripeReducer,
    textMessageGroups: TextMessageGroupsReducer,
    webBuilder: WebBuilderReducer,
  });
  return createStore(
    rootReducer,
    {},
    composedEnhancers,
  );
}

export function initAdminStore(): AdminStore {
  // Merge reducers into root reducer
  const rootReducer = combineReducers({
    advisorBoard: AdvisorBoardReducer,
    answeringSignup: AnsweringSignUpReducer,
    builder: BuilderReducer,
    features: UpgradeRequestReducer,
    menuManagement: menuManagementReducer,
    modals: ModalsReducer,
    newsNotification: NewsNotificationsReducer,
    offers: OffersReducer,
    ordering: OrderingReducer,
    orderingEventManagement: adminOrderingEventManagementReducer,
    orderManagement: adminOrderManagementReducer,
    replyModal: replyModalReducer,
    restaurantFeedback: adminRestaurantFeedbackReducer,
    snackbars: SnackbarsReducer,
    stripe: StripeReducer,
    textMessageGroups: TextMessageGroupsReducer,
    webBuilder: WebBuilderReducer,
  });
  return createStore(
    rootReducer,
    {},
    composedEnhancers,
  );
}

export function initConsumerStore(): ConsumerStore {
  // Merge reducers into root reducer
  const rootReducer = combineReducers({
    announcements: AnnouncementsReducer,
    consumer: ConsumerReducer,
    dishes: DishesReducer,
    featuredSection: FeaturedSectionReducer,
    features: UpgradeRequestReducer,
    menuItemCart: MenuItemCartReducer,
    modals: ModalsReducer,
    offers: OffersReducer,
    onlineOrdering: onlineOrderingReducer,
    stripe: StripeReducer,
  });
  return createStore(
    rootReducer,
    {},
    composedEnhancers,
  );
}
